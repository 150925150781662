import React, { useState, useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import { AppStore } from "../../../stores/AppStore";
import { Hub, Org, Site } from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import LoadingSpinner from "../../../spinner/LoadingSpinner";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

interface FrontelModalProps {
  appStore?: AppStore;
  onClose: () => void;
  open: boolean;
  org: Org | undefined;
  site: Site | undefined;
  hub: Hub | undefined;
}

const SetFrontelModal: React.FC<FrontelModalProps> = ({
  onClose,
  open,
  appStore,
  org = undefined,
  site = undefined,
  hub = undefined,
}: FrontelModalProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [orgId, setOrgId] = React.useState<number>(0);
  const [siteId, setSiteId] = React.useState<number>(0);
  const [frontelAccount, setFrontelAccount] = React.useState<string>("");
  const [frontelPanel, setFrontelPanel] =
    React.useState<string>("Panel not yet set");
  const [frontelDomain, setFrontelDomain] = React.useState<string>("");
  const [frontelConnectionDomain, setFrontelConnectionDomain] =
    React.useState<string>("");
  const [frontelConnectionPort, setFrontelConnectionPort] =
    React.useState<string>("");
  const environment = React.useRef<string>("staging");
  const [endButton, setEndButton] = React.useState<string>("Close");
  const [activateEnabled, setActivateEnabled] = useState(true);
  const [renderValue, setRenderValue] = useState(false);

  useEffect(() => {
    let url = window.location.href;
    if (url.includes("manage.deepalert")) {
      environment.current = "production";
    } else if (url.includes("manage.preprod")) {
      environment.current = "preprod";
    }
  }, [environment]);

  useEffect(() => {
    if (org) {
      setOrgId(org.org_id);
      setFrontelConnectionPort("1556");
      setEndButton("Cancel");
      setActivateEnabled(false);
      if (environment.current === "staging") {
        setFrontelConnectionDomain("frontel.staging.deepalert.ai");
      } else if (environment.current === "preprod") {
        setFrontelConnectionDomain("frontel.preprod.deepalert.ai");
      } else if (environment.current === "production") {
        setFrontelConnectionDomain("frontel.deepalert.ai");
      }
    }
  }, [renderValue, org]);

  useEffect(() => {
    if (site) {
      setSiteId(site.site_id);
      setFrontelAccount(site.meta.frontel_account);
      if (site.meta.frontel_panel && site.meta.frontel_panel !== "new site") {
        setFrontelPanel(site.meta.frontel_panel);
      }
      setFrontelConnectionPort("888");
      if (environment.current === "staging") {
        setFrontelConnectionDomain("direct.blazelight.dev");
      } else if (environment.current === "production") {
        setFrontelConnectionDomain("rsi.deepalert.ai");
      } else if (environment.current === "preprod") {
        setFrontelConnectionDomain("rsi.preprod.deepalert.ai");
      }
    }
  }, [appStore, site]);

  const handleDomain = (event: React.ChangeEvent<any>) => {
    setFrontelDomain(event.target.value);
    if (org) {
      setOrgId(org.org_id);
    }
    if (site) {
      setSiteId(site.site_id);
    }
  };

  const setFrontelOrg = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      await appStore?.functionService.activateFrontelOrg(
        token,
        orgId,
        frontelDomain
      );
    }
  };

  const setFrontelSite = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      await appStore?.functionService.activateFrontelSite(token, siteId);
    }
  };

  const handleClose = () => {
    setRenderValue((value) => !value);
    setFrontelAccount("");
    setFrontelPanel("Panel not yet set");
    setFrontelDomain("");
    onClose();
  };

  const StyledTableRowDynDns = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    })
  )(TableRow);

  const viewWindow = (
    <Dialog
      onClose={handleClose}
      open={open}
      className={classes.modal_dialog_wide}
      fullWidth={true}
    >
      {orgId ? (
        <DialogTitle className={classes.bold_text}>
          Activate Frontel for {org?.org_name}
        </DialogTitle>
      ) : siteId ? (
        <DialogTitle className={classes.bold_text}>
          Viewing Frontel details for {site?.name}
        </DialogTitle>
      ) : null}

      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {orgId ? (
            <TextField
              fullWidth
              label="DeepAlert domain"
              defaultValue={frontelConnectionDomain}
              disabled={true}
              variant="outlined"
              margin="normal"
              helperText="DeepAlert hosted Frontel AS server"
              InputProps={{
                className: classes.bold_text,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />
          ) : null}
          {orgId ? (
            <TextField
              fullWidth
              label="DeepAlert port"
              defaultValue={frontelConnectionPort}
              disabled={true}
              variant="outlined"
              margin="normal"
              helperText="DeepAlert hosted Frontel AS server port"
              InputProps={{
                className: classes.bold_text,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />
          ) : null}
          {orgId ? (
            <TextField
              fullWidth
              label="Your frontel server IP address"
              placeholder="please provide the IP address of your Frontel Server"
              onChange={handleDomain}
              required={true}
              variant="outlined"
              margin="normal"
              helperText="Copy from Frontel server"
              InputProps={{
                className: classes.bold_text,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />
          ) : null}

          {siteId ? (
            <TextField
              fullWidth
              label="domain name"
              defaultValue={frontelConnectionDomain}
              disabled={true}
              variant="outlined"
              margin="normal"
              helperText="DA hosted Frontel server"
              InputProps={{
                className: classes.bold_text,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />
          ) : null}
          {siteId ? (
            <TextField
              fullWidth
              label="port number"
              defaultValue={frontelConnectionPort}
              disabled={true}
              variant="outlined"
              margin="normal"
              helperText="DA hosted Frontel server port"
              InputProps={{
                className: classes.bold_text,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />
          ) : null}

          {siteId ? (
            <TextField
              fullWidth
              label="frontel account"
              defaultValue={frontelAccount}
              value={frontelAccount}
              disabled={true}
              variant="outlined"
              margin="normal"
              helperText="Frontel account number to be entered into your panel configuration"
              InputProps={{
                className: classes.bold_text,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />
          ) : null}

          {siteId ? (
            <TextField
              fullWidth
              label="frontel panel"
              defaultValue={frontelPanel}
              value={frontelPanel}
              disabled={true}
              variant="outlined"
              margin="normal"
              helperText="Frontel panel to which this account number has been assigned"
              InputProps={{
                className: classes.bold_text,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            />
          ) : null}
        </div>
      )}

      <div className={classes.twoButtonsInARow}>
        <Button
          variant="contained"
          className={classes.cancel_button}
          onClick={() => {
            setRenderValue((value) => !value);
            setFrontelDomain("");
            setFrontelAccount("");
            setFrontelPanel("");
            setFrontelConnectionDomain("");
            setFrontelConnectionPort("");
            onClose();
          }}
        >
          {endButton}
        </Button>
        {!siteId && (
          <Button
            variant="contained"
            className={classes.bold_text}
            disabled={activateEnabled}
            onClick={() => {
              if (orgId) {
                setFrontelOrg();
              }
              if (siteId) {
                setFrontelSite();
              }
              setRenderValue((value) => !value);
              setFrontelDomain("");
              setFrontelAccount("");
              onClose();
            }}
          >
            Activate
          </Button>
        )}
      </div>
    </Dialog>
  );

  return viewWindow;
};
export default SetFrontelModal;
