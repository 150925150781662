import React, { useState, useRef, useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import UserRuleEndPoint from "./UserRuleEndpoint";

import { AppStore } from "../../../stores/AppStore";
import {
  UserEndPointRoles,
  AlertChannel,
  AlertEndPointDef,
  Org,
  Sensor,
  User,
  UserWebRoles,
  Site,
} from "../../../services/FunctionService";
import { Typography, FormControlLabel, Checkbox } from "@material-ui/core";
import CameraMapping from "../../components/CameraMapping/CameraMapping";
import { Guid } from "guid-typescript";
import { useStyles } from "../../styling/global/global";
import MessageModal from "../MessageModal/MessageModal";
import AddHickCentral from "../AddHickCentralModal/AddHickCentral";
import HikCentralSecondModal from "../AddHickCentralModal/HikCentralSecondModal";

import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";

class impUserEndPointRoles implements UserEndPointRoles {
  key!: Guid;
  user_id!: number;
  web_roles!: { [key: string]: any };
  // end_point_roles!: { [key: string]: any };
}
interface AddAlertEndpointProps {
  currentOrg: Org;
  appStore?: AppStore;
  alertchannel: AlertChannel;
  open: boolean;
  onClose: () => void;
  saveNewEndpoint: (
    alert_channel_id: number,
    end_point_type: string,
    name: string,
    meta: { [key: string]: any },
    userendpointroles: UserEndPointRoles[],
    message: string
  ) => void;
  endpointdefs?: AlertEndPointDef[];
  sensors: Sensor[];
  forceRefresh: () => void;
  saveRevisedNewEndpoint: (
    end_point_type: string,
    alertchannel: AlertChannel
  ) => void;
  refreshLoading: () => void;
  site: Site | undefined;
}
interface Data {
  user_id: number;
  username: string;
  first_name: string;
  last_name: string;
  supervisor: boolean;
  agent: boolean;
}
class impUserWebRoles implements UserWebRoles {
  first_name!: string;
  last_name!: string;
  user_id!: number;
  supervisor!: boolean;
  agent!: boolean;
  username!: string;
}
const AddAlertChannelEndpointModal: React.FC<AddAlertEndpointProps> = ({
  forceRefresh,
  currentOrg,
  appStore,
  alertchannel,
  open,
  onClose,
  endpointdefs,
  sensors,
  saveRevisedNewEndpoint,
  saveNewEndpoint,
  refreshLoading,
  site,
}: AddAlertEndpointProps) => {
  console.log("SENSORS-PROPS: ", sensors);

  const classes = useStyles();
  const [message, setMessage] = React.useState("");
  const [endpointtype, setEndPointType] = React.useState("");
  const [values, setValues] = React.useState<any[]>([]);
  const [cameramappings, setCameraMappings] = React.useState<any[]>([]);
  const [userruleendpointvalues, setUserRuleEndpointValues] = React.useState<
    UserEndPointRoles[]
  >([]);
  const [users, setUsers] = React.useState<User[]>([]);
  const [userwebroles, setUserWebRoles] = React.useState<UserWebRoles[]>([]);
  const [selecteduserwebroles, setSelectedUserWebRoles] = React.useState<
    UserWebRoles[]
  >([]);
  const [refresh, setRefresh] = React.useState(0);
  const [groupid, setGroupId] = React.useState(0);
  const [loadingrows, setLoadingRows] = React.useState(false);
  const [orgslist, setOrgsList] = React.useState<Org[]>([]);
  const [selectError, setSelectError] = useState(false);
  const [openAddHickCentralModal, setOpenHickCentralModal] = useState(false);
  const [openHikCentralSecondModal, setOpenHikCentralSecondModal] =
    useState(false);
  const [hickCentralMessage, setHickCentralMessage] = useState("first modal");
  const [innerChannelEndpointId, setInnerChannelEndpointId] = useState(0);
  const [telegramBotId, setTelegramBotId] = useState(-1);
  const [isSysAdmin, setIsSysAdmin] = React.useState(false);
  const deviceNum = React.useRef<Number>(0);

  const closeHickCentralModal = () => {
    refreshLoading();
    handleClose();
    setOpenHickCentralModal(false);
  };
  const goBackToFirstHikModal = () => {
    setOpenHikCentralSecondModal(false);
    setOpenHickCentralModal(true);
    setHickCentralMessage("first modal");
  };
  const closeHikCentralSecondModal = () => {
    setHickCentralMessage("");
    refreshLoading();
    setOpenHikCentralSecondModal(false);
    handleClose();
  };
  useEffect(() => {
    const fetchData = async () => {
      if (appStore!.authService.getRoles().indexOf("sys_admin") >= 0) {
        setIsSysAdmin(true);
      }
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        const orglist = await appStore?.functionService.getOrgsList(token);
        if (orglist && orglist.success) {
          setOrgsList(orglist?.orgs);
        }
        const listenerDeviceNum =
          await appStore?.functionService.getListenerDeviceNum(token);
        if (listenerDeviceNum) {
          deviceNum.current = listenerDeviceNum;
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && groupid != 0) {
        var newuserwebroles: UserWebRoles[] = [];
        var allusers: User[];
        if (groupid == -1) {
          allusers = await appStore?.functionService.getAllVisibleUsers(
            token,
            appStore?.authService.getLoggedInOrg()
          );
        } else {
          allusers = await appStore?.functionService.getAllUsersForOrg(
            token,
            groupid
          );
        }
        setUsers(allusers!);
        allusers?.forEach((user) => {
          let isincurrentselected = selecteduserwebroles.find(
            (x) => x.user_id === user.user_id
          );
          if (!isincurrentselected) {
            let userwebrole = new impUserWebRoles();
            userwebrole.user_id = user.user_id;
            userwebrole.username = user.username;
            userwebrole.first_name = user.first_name;
            userwebrole.last_name = user.last_name;
            userwebrole.supervisor = false;
            userwebrole.agent = false;
            newuserwebroles.push(userwebrole);
          }
        });
        setUserWebRoles(newuserwebroles);
        setLoadingRows(false);
        setRefresh(new Date().getTime());
      }
    };
    fetchData();
  }, [groupid, refresh]);
  useEffect(() => {
    const fetchData = () => {
      let emptyvalues: any[] = [];
      sensors.forEach((sensor) => {
        let thisvalue: { [key: string]: any } = {};
        thisvalue[sensor.sensor_id_str] = "";
        emptyvalues.push(thisvalue);
      });
      setCameraMappings(emptyvalues);
    };
    fetchData();
  }, [sensors]);
  function createData(
    user_id: number,
    username: string,
    first_name: string,
    last_name: string,
    supervisor: boolean,
    agent: boolean
  ): Data {
    return { user_id, username, first_name, last_name, supervisor, agent };
  }

  const addUser = () => {
    const newuser = new impUserEndPointRoles();
    newuser.key = Guid.create();
    userruleendpointvalues.push(newuser);
    setUserRuleEndpointValues(userruleendpointvalues);
    setRefresh(new Date().getTime());
  };
  var userruleendpoints: React.ReactNode[] = [];
  if (endpointtype == "user_app") {
    if (userruleendpointvalues.length == 0) {
      addUser();
    }
    var count = 0;
    userruleendpointvalues.forEach((value) => {
      var addbutton = false;
      if (count == userruleendpointvalues.length - 1) {
        addbutton = true;
      }
      userruleendpoints.push(
        <div>
          <UserRuleEndPoint
            addbutton={addbutton}
            users={users}
            value={value}
            handleUserRuleEndPointChange={(x: UserEndPointRoles) =>
              handleUserRuleEndPointChange(x)
            }
            endpointtype={endpointtype}
            appStore={appStore}
            alertchannel={alertchannel}
            addUser={() => addUser()}
          ></UserRuleEndPoint>
        </div>
      );
      count++;
    });
  }
  var endpointtypes: React.ReactNode[] = [];

  const handleClose = () => {
    setEndPointType("");
    setValues([]);
    setUserRuleEndpointValues([]);
    endpointtypes = [];
    setUserWebRoles([]);
    setSelectedUserWebRoles([]);
    onClose();
  };
  const handleSave = () => {
    var definition: { [key: string]: string } = {};
    if (endpointdefs) {
      endpointdefs.forEach((epdef) => {
        if (epdef.end_point_type == endpointtype) {
          definition = epdef.definition;
        }
      });
    }
    const unique =
      appStore?.functionService.translate(
        alertchannel.locale,
        "endpoint_names",
        endpointtype
      ) + new Date().getTime().toString();
    var meta: { [key: string]: any } = {};
    let errorChecker = "no";
    values.forEach((valueitem) => {
      if (valueitem.type == "list") {
        if (valueitem.value == "") {
          errorChecker = "error";
          setSelectError((valu) => true);
        } else {
          let val: number = Number(valueitem.value);
          meta[valueitem.key] = val;
        }
        if (valueitem.key === "bot") {
          meta[valueitem.key] = telegramBotId;
        }
      } else if (valueitem.type == "boolean") {
        let val: boolean = valueitem.value;
        meta[valueitem.key] = val;
      } else if (
        valueitem.type == "number" ||
        valueitem.type == "integer" ||
        valueitem.type == "long"
      ) {
        let val: number = Number(valueitem.value);
        meta[valueitem.key] = val;
      } else {
        meta[valueitem.key] = valueitem.value;
      }
    });
    var mapping: { [key: string]: any } = {};
    var map = 0;
    cameramappings.forEach((cameramapping) => {
      for (let key in cameramapping) {
        if (definition["mappingtype"] == "integer") {
          mapping[key] = Number(cameramapping[key]);
        } else {
          mapping[key] = cameramapping[key];
        }
        map++;
      }
    });
    if (map > 0) {
      meta["mapping"] = mapping;
    }
    if (errorChecker !== "error") {
      onClose();
      saveNewEndpoint(
        alertchannel.alert_channel_id,
        endpointtype,
        unique,
        meta,
        userruleendpointvalues,
        "Created new"
      );
      setEndPointType("");
      setValues([]);
      setUserRuleEndpointValues([]);
      endpointtypes = [];
      forceRefresh();
    }
  };

  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [alertchannelmessage, setAlertchannelmessage] = useState("");
  const closeMessageModal = () => {
    setAlertchannelmessage("");
    setMessageModalOpen(false);
  };

  const handleBotChange = (e: any) => {
    setTelegramBotId(+e.target.value);
  };

  const handleSaveEndpoint = () => {
    handleClose();
    onClose();
    saveRevisedNewEndpoint(endpointtype, alertchannel);
    forceRefresh();
  };

  if (messageModalOpen) {
    return (
      <MessageModal
        open={messageModalOpen}
        onClose={closeMessageModal}
        message={alertchannelmessage}
        elements={undefined}
      />
    );
  }
  const handleUserRuleEndPointChange = (
    userendpointrules: UserEndPointRoles
  ) => {
    const currentforuser = userruleendpointvalues.find(
      (x) => x.key == userendpointrules.key
    );
    if (currentforuser) {
      userruleendpointvalues[
        userruleendpointvalues.indexOf(currentforuser)
      ].web_roles = userendpointrules.web_roles;
      userruleendpointvalues[
        userruleendpointvalues.indexOf(currentforuser)
      ].user_id = userendpointrules.user_id;
    }
    const undefineduser = userruleendpointvalues.find(
      (x) => x.user_id == undefined
    );
    if (undefineduser) {
      userruleendpointvalues.splice(
        userruleendpointvalues.indexOf(undefineduser),
        1
      );
    }
    setUserRuleEndpointValues(userruleendpointvalues);
  };
  const handleEndPointTypeChange = (event: React.ChangeEvent<any>) => {
    if (event.target.value === "hikcentral") {
      setHickCentralMessage("first modal");
      setOpenHickCentralModal(true);
    } else {
      setEndPointType(event.target.value);
      setValues([]);
      endpointtypes = [];
    }
  };
  const handleCameraMappingsChange = (newvalues: any[]) => {
    setCameraMappings(newvalues);
  };

  const handleValueChange = (event: React.ChangeEvent<any>) => {
    setSelectError(false);
    let value = event.target.value;
    let key = event.target.name;
    let newvalues: any[] = [];
    values.forEach((valueitem) => {
      if (valueitem.key == key) {
        if (valueitem.type == "boolean") {
          valueitem.value = !valueitem.value;
        } else {
          valueitem.value = value;
        }
      }
      newvalues.push(valueitem);
    });
    setValues(newvalues);
  };
  if (appStore!.authService.getRoles().indexOf("site_admin") >= 0) {
    if (alertchannel) {
      if (endpointdefs) {
        endpointdefs.forEach((endpointdef) => {
          const translate = appStore?.functionService.translate(
            alertchannel.locale,
            "endpoint_names",
            endpointdef.end_point_type
          );
          if (translate === "unusual_alert") {
            if (appStore!.authService!.getRoles()!.indexOf("sys_admin") >= 0) {
              endpointtypes.push(
                <MenuItem value={endpointdef.end_point_type}>
                  <span className={classes.bold_text}>{translate}</span>
                </MenuItem>
              );
            }
          } else {
            endpointtypes.push(
              <MenuItem value={endpointdef.end_point_type}>
                <span className={classes.bold_text}>{translate}</span>
              </MenuItem>
            );
          }
        });
      }
      var endpointform: React.ReactNode[] = [];

      if (endpointtype && endpointtype !== "" && endpointdefs) {
        let emptyvalues: any[] = [];
        endpointdefs.forEach((endpointdef) => {
          if (endpointdef.end_point_type === endpointtype) {
            const definition = endpointdef.definition;

            for (const key in definition) {
              if (key !== "mappingtype") {
                const field = definition[key];
                const translate = appStore?.functionService.translate(
                  alertchannel.locale,
                  "endpoint_fields",
                  key
                );

                let thisvalue = values.find((i) => i.key == key);
                if (field == "string") {
                  if (!thisvalue) {
                    thisvalue = { key: key, value: "", type: field };
                    emptyvalues.push(thisvalue);
                  }

                  endpointform.push(
                    <TextField
                      name={key}
                      label={translate}
                      onChange={(e) => handleValueChange(e)}
                      value={thisvalue.value}
                      variant="outlined"
                      margin="normal"
                      autoComplete=""
                      fullWidth
                      style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        marginTop: 5,
                      }}
                      InputProps={{
                        className: classes.bold_text,
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.bold_text,
                        },
                        style: {
                          marginLeft: 15,
                        },
                      }}
                    />
                  );
                } else if (
                  field == "number" ||
                  field == "integer" ||
                  field == "long"
                ) {
                  if (!thisvalue) {
                    if (key == "deviceno" && endpointtype == "listener") {
                      let setDevice = deviceNum.current;
                      thisvalue = {
                        key: key,
                        value: setDevice,
                        type: field,
                      };
                    } else if (
                      key == "deviceno" &&
                      translate == "Site Number"
                    ) {
                      thisvalue = {
                        key: key,
                        value: alertchannel.site_id,
                        type: field,
                      };
                    } else {
                      thisvalue = { key: key, value: 0, type: field };
                    }
                    emptyvalues.push(thisvalue);
                  }
                  let disabled = false;
                  if (key == "deviceno" && translate == "Site Number") {
                    disabled = true;
                  }
                  endpointform.push(
                    <TextField
                      disabled={disabled}
                      name={key}
                      type="number"
                      label={translate}
                      onChange={(e) => handleValueChange(e)}
                      value={thisvalue.value}
                      variant="outlined"
                      margin="normal"
                      autoComplete=""
                      fullWidth
                      style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        marginTop: 0,
                      }}
                      InputProps={{
                        className: classes.bold_text,
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.bold_text,
                        },
                        style: {
                          marginLeft: 15,
                        },
                      }}
                    />
                  );
                } else if (field == "password") {
                  if (!thisvalue) {
                    thisvalue = { key: key, value: "", type: field };
                    emptyvalues.push(thisvalue);
                  }
                  endpointform.push(
                    <TextField
                      name={key}
                      type="password"
                      label={translate}
                      onChange={(e) => handleValueChange(e)}
                      value={thisvalue.value}
                      variant="outlined"
                      margin="normal"
                      autoComplete=""
                      fullWidth
                      style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        marginTop: 0,
                      }}
                      InputProps={{
                        className: classes.bold_text,
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.bold_text,
                        },
                        style: {
                          marginLeft: 15,
                        },
                      }}
                    />
                  );
                } else if (field == "camera_map") {
                  if (!thisvalue) {
                    thisvalue = { key: key, value: [], type: field };
                    emptyvalues.push(thisvalue);
                  }
                  endpointform.push(
                    <>
                      <br></br>
                      <FormLabel className={classes.bold_text}>
                        {translate + ":"}
                      </FormLabel>
                      <CameraMapping
                        mappingtype={definition["mappingtype"]}
                        disabled={false}
                        handleCameraMappingsChange={handleCameraMappingsChange}
                        sensors={sensors}
                        value={cameramappings}
                      />
                    </>
                  );
                } else if (field == "boolean") {
                  if (!thisvalue) {
                    thisvalue = { key: key, value: false, type: field };
                    emptyvalues.push(thisvalue);
                  }
                  endpointform.push(
                    <FormControlLabel
                      className={classes.bold_text}
                      control={
                        <Checkbox
                          className={classes.form_component_checkbox}
                          name={key}
                          onChange={(e) => handleValueChange(e)}
                          checked={thisvalue.value}
                        />
                      }
                      label={translate}
                    />
                  );
                } else if (translate === "Telegram Bot") {
                  var items: React.ReactNode[] = [];
                  if (field) {
                    if (!thisvalue) {
                      thisvalue = { key: key, value: "", type: "list" };
                      emptyvalues.push(thisvalue);
                    }
                    var dict: {} = field;
                    let deepalertbot = "";
                    let bot_number = 0;
                    for (const fkey in dict) {
                      const subfield = field[fkey];
                      thisvalue.value = fkey;
                      bot_number = Number(fkey);
                      deepalertbot = subfield;
                      items.push(
                        <MenuItem value={fkey}>
                          <span className={classes.bold_text}>{subfield}</span>
                        </MenuItem>
                      );
                    }
                    if (telegramBotId == -1) {
                      setTelegramBotId(bot_number);
                    }
                    endpointform.push(
                      <>
                        <FormControl className={classes.form_component}>
                          <InputLabel
                            style={{ marginLeft: 15 }}
                            className={classes.bold_text}
                            id="test-select-label"
                          >
                            {translate}
                          </InputLabel>
                          <Select
                            variant="outlined"
                            labelId="test-select-label"
                            label={translate}
                            name={key}
                            error={selectError}
                            value={
                              telegramBotId > -1
                                ? telegramBotId
                                : thisvalue.value
                            }
                            fullWidth
                            // style = {{ }}
                            disabled={!isSysAdmin}
                            onChange={(e) => handleBotChange(e)}
                          >
                            {items}
                          </Select>
                        </FormControl>
                        <FormControl className={classes.form_component}>
                          <p
                            style={{
                              fontSize: 12,
                              textAlign: "center",
                              margin: 3,
                              marginBottom: 0,
                              width: 250,
                            }}
                            className={classes.bold_text}
                          >
                            Please create a group on Telegram adding the bot{" "}
                            <i>{deepalertbot}</i> as a member.
                          </p>
                          <p
                            style={{
                              fontSize: 12,
                              textAlign: "center",
                              margin: 3,
                              marginBottom: 0,
                              width: 250,
                            }}
                            className={classes.bold_text}
                          >
                            Then use the command /h to retrieve the Telegram
                            Group ID and copy/paste the number in the field
                            below.
                          </p>
                        </FormControl>
                      </>
                    );
                  }
                } else {
                  //Assume a select option with different values
                  var items: React.ReactNode[] = [];
                  if (field) {
                    if (!thisvalue) {
                      thisvalue = { key: key, value: "", type: "list" };
                      emptyvalues.push(thisvalue);
                    }
                    var dict: {} = field;
                    for (const fkey in dict) {
                      const subfield = field[fkey];
                      items.push(
                        <MenuItem value={fkey}>
                          <span className={classes.bold_text}>{subfield}</span>
                        </MenuItem>
                      );
                    }
                    endpointform.push(
                      <FormControl className={classes.form_component}>
                        <InputLabel
                          style={{ marginLeft: 15 }}
                          className={classes.bold_text}
                          id="test-select-label"
                        >
                          {translate}
                        </InputLabel>
                        <Select
                          variant="outlined"
                          labelId="test-select-label"
                          label={translate}
                          name={key}
                          error={selectError}
                          value={thisvalue.value}
                          fullWidth
                          onChange={(e) => handleValueChange(e)}
                        >
                          {items}
                        </Select>
                      </FormControl>
                    );
                  }
                }
                endpointform.push(<br></br>);
              }
            }
          }
        });
        if (values.length == 0 && emptyvalues.length > 0) {
          setValues(emptyvalues);
        }
      }
      if (endpointtype == "web") {
        var otherorgs: React.ReactNode[] = [];
        otherorgs.push(
          <MenuItem value={-1}>
            <span className={classes.bold_text}>All Users</span>
          </MenuItem>
        );
        if (orgslist && orgslist.length > 0) {
          orgslist.forEach((org) => {
            otherorgs.push(
              <MenuItem value={org.org_id}>
                <span className={classes.bold_text}>{org.org_name}</span>
              </MenuItem>
            );
          });
        }
        var rows: Data[] = [];
        if (userwebroles && userwebroles.length > 0) {
          userwebroles.forEach((userwebrole) => {
            rows.push(
              createData(
                userwebrole.user_id,
                userwebrole.username,
                userwebrole.first_name,
                userwebrole.last_name,
                userwebrole.supervisor ? true : false,
                userwebrole.agent ? true : false
              )
            );
          });
        }
        var seletedrows: Data[] = [];
        if (selecteduserwebroles && selecteduserwebroles.length > 0) {
          selecteduserwebroles.forEach((userwebrole) => {
            seletedrows.push(
              createData(
                userwebrole.user_id,
                userwebrole.username,
                userwebrole.first_name,
                userwebrole.last_name,
                userwebrole.supervisor ? true : false,
                userwebrole.agent ? true : false
              )
            );
          });
        }
        if (
          (rows.length > 0 || otherorgs.length > 0 || seletedrows.length > 0) &&
          !loadingrows
        ) {
          return (
            <Dialog
              onClose={handleClose}
              aria-labelledby="simple-dialog-title"
              open={open}
              className="edit-dialog"
            >
              <DialogTitle id="simple-dialog-title">
                <span className={classes.bold_text}>
                  Save New Web Alert Endpoint to {alertchannel.name} ?
                </span>
              </DialogTitle>

              <div className={classes.twoButtonsInARow}>
                <Button
                  variant="contained"
                  className="save"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="save"
                  onClick={handleSaveEndpoint}
                >
                  Save
                </Button>
              </div>
            </Dialog>
          );
        } else {
          return (
            <Dialog
              onClose={handleClose}
              className={classes.modal_dialog}
              open={open}
            >
              <DialogTitle>
                <span className={classes.bold_text}>
                  Add New Web Alert Endpoint to {alertchannel.name}
                </span>
              </DialogTitle>
              <Typography className={classes.bold_text} color="error">
                {message}
              </Typography>
              <TableContainer component={Paper}>
                <div className="wait-container">
                  <svg width="48px" height="48px" viewBox="0 0 128 128">
                    <g>
                      <linearGradient id="linear-gradient">
                        <stop offset="0%" stop-color="#ffffff" />
                        <stop offset="100%" stop-color="#22305f" />
                      </linearGradient>
                      <path
                        d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                        fill="url(#linear-gradient)"
                        fill-rule="evenodd"
                      />
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 64 64"
                        to="360 64 64"
                        dur="1080ms"
                        repeatCount="indefinite"
                      ></animateTransform>
                    </g>
                  </svg>
                </div>
              </TableContainer>
              <div className={classes.button_container}>
                <Button
                  variant="contained"
                  className={classes.cancel_button}
                  onClick={handleClose}
                >
                  <span className={classes.bold_text}>CANCEL</span>
                </Button>
              </div>
            </Dialog>
          );
        }
      } else {
        return (
          <>
            <Dialog
              onClose={handleClose}
              className={classes.modal_dialog}
              open={open}
            >
              <DialogTitle>
                <p
                  className={classes.bold_text}
                  style={{ marginTop: 10, marginBottom: 5 }}
                >
                  Add New Alert Endpoint to {alertchannel.name}
                </p>
              </DialogTitle>
              <Typography className={classes.bold_text} color="error">
                {message}
              </Typography>
              <FormControl
                variant="outlined"
                className={classes.form_component}
              >
                <InputLabel className={classes.bold_text}>
                  Endpoint Type
                </InputLabel>
                <Select
                  value={endpointtype}
                  onChange={(e) => handleEndPointTypeChange(e)}
                >
                  {endpointtypes}
                </Select>
              </FormControl>
              {endpointform}
              {userruleendpoints}
              <div className={classes.button_container}>
                <Button
                  variant="contained"
                  className={classes.cancel_button}
                  onClick={handleClose}
                >
                  <span className={classes.bold_text}>CANCEL</span>
                </Button>
                <Button
                  variant="contained"
                  className={classes.save_button}
                  onClick={handleSave}
                >
                  <span className={classes.bold_text}>SAVE</span>
                </Button>
              </div>
            </Dialog>
            <AddHickCentral
              open={openAddHickCentralModal}
              onClose={closeHickCentralModal}
              message={hickCentralMessage}
              appStore={appStore}
              alert_channel_id={alertchannel.alert_channel_id}
              site={site!}
            />
            <HikCentralSecondModal
              open={openHikCentralSecondModal}
              onClose={closeHikCentralSecondModal}
              message={hickCentralMessage}
              onBack={goBackToFirstHikModal}
              alert_channel_end_point_id={innerChannelEndpointId}
              appStore={appStore}
            />
          </>
        );
      }
    } else {
      return <div></div>;
    }
  } else {
    return (
      <Dialog onClose={onClose} open={open} className={classes.modal_dialog}>
        <DialogTitle className={classes.bold_text}>
          Add New Alert Endpoint to {alertchannel.name}
        </DialogTitle>
        <br></br>
        <Typography className={classes.bold_text}>
          {
            "You need site admin role to be able to add a new Delivery Endpoint - please contact your DeepAlert system administrator"
          }
        </Typography>
        <div className={classes.button_container}>
          <Button
            className={classes.cancel_button}
            variant="contained"
            onClick={() => onClose()}
          >
            <span className={classes.bold_text}>CANCEL</span>
          </Button>
        </div>
      </Dialog>
    );
  }
};

export default AddAlertChannelEndpointModal;
