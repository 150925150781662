import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useStyles } from "../../styling/global/global";
import moment from "moment-timezone";

interface ExpansionPanelMenuProps {
  theOrg?: any;
  defaultRules?: any;
  openAddDefaultsModal?: any;
  activeOrg?: any;
  reloadLogos?: any;
  thumbnail?: any;
  currentOrg?: number;
  appStore?: any;
  del?: boolean;
  sentinel?: boolean;
  edit?: boolean;
  enable?: boolean;
  enabled?: boolean;
  move: boolean;
  enter: boolean;
  rename_site: boolean;
  training: boolean;
  billingratesedit: boolean;
  enableGroup?: boolean;
  showEnableOrDisableGroup?: boolean;
  disableGroupDate?: string;
  handleShowGroup?: (x: any) => void;
  handleEdit: (x: any) => void;
  handleAddGroupToSentinel: () => void;
  handleAddGroupToImmix?: () => void;
  handleEnableGroup?: () => void;
  handleGenerateSentinelToken: () => void;
  handleShowSentinelToken: () => void;
  handleEnable: () => void;
  handleDelete: () => void;
  handleMove: () => void;
  handleBillingRatesEdit: () => void;
  alert_smtp_client: boolean;
  handleSmtpPasswordChange: () => void;
  handleRenameSite: () => void;
  report: boolean;
  rules: boolean;
  nvr: boolean;
  siteDynDNS: boolean;
  requestDynDNS: boolean;
  canSetDynDNS: boolean;
  canViewDynDNS: boolean;
  canViewFrontel: boolean;
  view_users: boolean;
  disconnectOption: boolean;
  disconnectStatus: boolean;
  handleDisconnect: () => void;
  handleDefaultRulesOrg?: () => void;
  handleNvr: () => void;
  handleActivateDynDns: () => void;
  handleSetDynDns: () => void;
  handleViewDynDns: () => void;
  handleTraining: () => void;
  handleAddTele: (x: any) => void;
  handleAddGroupToFrontel: () => void;
  handleAddSiteToFrontel: () => void;
  site: any;
}
const ExpansionPanelMenu: React.FC<ExpansionPanelMenuProps> = ({
  site,
  defaultRules,
  openAddDefaultsModal,
  activeOrg,
  handleDefaultRulesOrg,
  reloadLogos,
  theOrg,
  move,
  sentinel,
  del,
  edit,
  enable,
  enabled,
  billingratesedit,
  enableGroup,
  showEnableOrDisableGroup,
  handleShowGroup,
  handleAddGroupToSentinel,
  handleAddGroupToImmix,
  handleEnableGroup,
  nvr,
  siteDynDNS,
  requestDynDNS,
  canSetDynDNS,
  canViewDynDNS,
  canViewFrontel,
  training,
  rename_site,
  handleMove,
  handleEdit,
  handleEnable,
  handleDelete,
  handleBillingRatesEdit,
  alert_smtp_client,
  handleSmtpPasswordChange,
  report,
  rules,
  enter,
  view_users,
  disconnectOption,
  disconnectStatus,
  handleDisconnect,
  handleNvr,
  handleActivateDynDns,
  handleSetDynDns,
  handleViewDynDns,
  disableGroupDate,
  handleRenameSite,
  handleTraining,
  handleGenerateSentinelToken,
  handleShowSentinelToken,
  handleAddTele,
  handleAddGroupToFrontel,
  handleAddSiteToFrontel,
}: ExpansionPanelMenuProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const enabletext = enabled ? "disable" : "enable";
  const enableGroupText = enableGroup ? "disable group" : "enable group";
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [openModal, setOpenModal] = React.useState(false);

  const [openRuleModal, setOpenRuleModal] = React.useState<boolean>(false);
  const handleOpenRuleModal = () => setOpenRuleModal(true);
  const handleCloseRuleModal = () => setOpenRuleModal(false);
  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const [Thumbnail, setThumbnail] = useState<{
    logo: { stringb64: string } | null;
  }>({
    logo: activeOrg?.logo?.thumbnail,
  });

  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const modalHandler = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const Edit = (x: any) => {
    setAnchorEl(null);
    handleEdit(x);
  };
  const AddTele = (x: any) => {
    setAnchorEl(null);
    handleAddTele(x);
  };
  const Enable = () => {
    setAnchorEl(null);
    handleEnable();
  };
  const Delete = () => {
    setAnchorEl(null);
    handleDelete();
  };
  const Move = () => {
    setAnchorEl(null);
    handleMove();
  };
  const BillingRateEdit = () => {
    setAnchorEl(null);
    handleBillingRatesEdit();
  };
  const ChangePassword = () => {
    setAnchorEl(null);
    handleSmtpPasswordChange();
  };
  const handleDisconnectNotifications = () => {
    setAnchorEl(null);
    handleDisconnect();
  };
  const Nvr = () => {
    setAnchorEl(null);
    handleNvr();
  };
  const ActivateDynDNS = () => {
    setAnchorEl(null);
    handleActivateDynDns();
  };
  const SetDynDNS = () => {
    setAnchorEl(null);
    handleSetDynDns();
  };
  const ViewDynDNS = () => {
    setAnchorEl(null);
    handleViewDynDns();
  };
  const Rename = () => {
    setAnchorEl(null);
    handleRenameSite();
  };
  const Train = () => {
    setAnchorEl(null);
    handleTraining();
  };
  const handleShowToken = () => {
    setAnchorEl(null);
    handleShowSentinelToken();
  };

  const handleGenerateToken = () => {
    setAnchorEl(null);
    handleGenerateSentinelToken();
  };
  const AddGroupToSentinel = () => {
    setAnchorEl(null);
    handleAddGroupToSentinel();
  };
  const AddGroupToImmix = () => {
    setAnchorEl(null);
    handleAddGroupToImmix!();
  };
  const AddGroupToFrontel = () => {
    setAnchorEl(null);
    handleAddGroupToFrontel();
  };
  const AddSiteToFrontel = () => {
    setAnchorEl(null);
    handleAddSiteToFrontel();
  };
  const EnableGroup = () => {
    setAnchorEl(null);
    handleEnableGroup!();
  };

  const handleGroupOrg = () => {
    setAnchorEl(null);
    openAddDefaultsModal();
    handleDefaultRulesOrg!();
  };

  var menuitems: React.ReactNode[] = [];
  if (enter) {
    menuitems.push(
      <MenuItem onClick={Edit}>
        <span className={classes.bold_text}>view site</span>
      </MenuItem>
    );
    if (canViewFrontel) {
      menuitems.push(
        <MenuItem onClick={AddSiteToFrontel}>
          <span className={classes.bold_text}>View Frontel Details</span>
        </MenuItem>
      );
    }
  } else if (edit) {
    if (!view_users && !site?.data_origin.includes("motioncam")) {
      menuitems.push(
        <MenuItem onClick={Edit}>
          <span className={classes.bold_text}>edit</span>
        </MenuItem>
      );
    }

    if (view_users) {
      menuitems.push(
        <MenuItem onClick={AddTele}>
          <span className={classes.bold_text}>settings</span>
        </MenuItem>
      );
    }
  }
  if (sentinel) {
    menuitems.push(
      <MenuItem onClick={AddGroupToSentinel}>
        <span className={classes.bold_text}>
          sentinel post-analytics product
        </span>
      </MenuItem>
    );
    menuitems.push(
      <MenuItem onClick={AddGroupToImmix}>
        <span className={classes.bold_text}>immix product</span>
      </MenuItem>
    );
    // TODO: JASON -->> frontel group activation access
    // menuitems.push(
    //   <MenuItem onClick={AddGroupToFrontel}>
    //     <span className={classes.bold_text}>Frontel Org Activation</span>
    //   </MenuItem>
    // );
  }

  if (showEnableOrDisableGroup) {
    menuitems.push(
      <MenuItem onClick={EnableGroup}>
        <span className={classes.bold_text}>
          {enableGroupText}
          {disableGroupDate && enableGroup !== true
            ? ` ( disabled date: ${moment(disableGroupDate).format(
                "YYYY-MM-DD HH:mm"
              )} )`
            : ""}{" "}
        </span>
      </MenuItem>
    );
  }

  if (move) {
    menuitems.push(
      <MenuItem onClick={Move}>
        <span className={classes.bold_text}>
          {enter ? "move site" : "move"}
        </span>
      </MenuItem>
    );
  }
  if (enable) {
    menuitems.push(
      <MenuItem onClick={Enable}>
        <span className={classes.bold_text}>{enabletext}</span>
      </MenuItem>
    );
  }
  if (del) {
    menuitems.push(
      <MenuItem onClick={Delete}>
        <span className={classes.bold_text}>
          {enter ? "delete site" : "delete"}
        </span>
      </MenuItem>
    );
  }

  if (billingratesedit) {
    menuitems.push(
      <MenuItem onClick={BillingRateEdit}>
        <span className={classes.bold_text}>change product</span>
      </MenuItem>
    );
  }
  if (alert_smtp_client) {
    menuitems.push(
      <MenuItem onClick={ChangePassword}>
        <span className={classes.bold_text}>change SMTP password</span>
      </MenuItem>
    );
  }
  if (disconnectOption) {
    menuitems.push(
      <MenuItem onClick={handleDisconnectNotifications}>
        <span className={classes.bold_text}>
          {" "}
          {disconnectStatus === true ? "disable" : "enable"} disconnection
          notifications
        </span>
      </MenuItem>
    );
  }
  if (nvr) {
    menuitems.push(
      <MenuItem onClick={Nvr}>
        <span className={classes.bold_text}>
          view site SMTP Connection settings
        </span>
      </MenuItem>
    );
  }
  if (siteDynDNS) {
    if (!requestDynDNS) {
      menuitems.push(
        <MenuItem onClick={ActivateDynDNS}>
          <span className={classes.bold_text}>Activate Site DynDNS</span>
        </MenuItem>
      );
    }
    if (canSetDynDNS) {
      menuitems.push(
        <MenuItem onClick={SetDynDNS}>
          <span className={classes.bold_text}>Set Site DynDNS</span>
        </MenuItem>
      );
    }
    if (canViewDynDNS) {
      menuitems.push(
        <MenuItem onClick={ViewDynDNS}>
          <span className={classes.bold_text}>View Site DynDNS</span>
        </MenuItem>
      );
    }
  }
  if (rename_site) {
    menuitems.push(
      <MenuItem onClick={Rename}>
        <span className={classes.bold_text}>rename site</span>
      </MenuItem>
    );
  }
  if (defaultRules) {
    menuitems.push(
      <MenuItem onClick={handleGroupOrg}>
        <span className={classes.bold_text}>default rules settings</span>
      </MenuItem>
    );
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onMouseLeave={handleClose}
      >
        {menuitems}
      </Menu>
    </div>
  );
};
export default ExpansionPanelMenu;
