import React, { useState, useEffect, useRef } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import { Divider } from "@material-ui/core";
import RightDrawer from "../../containers/RightDrawer/RightDrawer";
import { AppStore } from "../../../stores/AppStore";
import AdvancedRulesWizard from "./AdvancedRulesWizard";
import CustomButton from "../../UI/CustomButton";
import Modal from "@material-ui/core/Modal";
import { message as msg } from "antd";
import ClearIcon from "@material-ui/icons/Clear";
import {
  Site,
  Sensor,
  Hub,
  Classifier,
  CanvasImage,
  MainMenu,
  Org,
  SensorHealth,
  SnapshotFromServer,
  DataOriginDefaults,
  DataOriginElements,
  UpdateSensorResult,
} from "../../../services/FunctionService";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import SecurityAdvanced from "../../modals/AddCameraModal/SecurityAdvanced";
import SafetyAdvanced from "../../modals/AddCameraModal/SafetyAdvanced";
import WeaponAdvanced from "../../modals/AddCameraModal/WeaponAdvanced";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import { useStyles } from "../../styling/global/global";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DrawCanvas from "../Snapshot/DrawCanvas";
import MessageModal from "../../modals/MessageModal/MessageModal";
import CameraIcon from "@material-ui/icons/Camera";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Alert from "@material-ui/lab/Alert";
import { TimeEntrySet, TimeEntry } from "../../../core/models/TimeEntry";
import TimeEntryPanel from "../../components/TimeEntryUI/TimeEntryPanel";
import { AddCircle, SettingsInputHdmi } from "@material-ui/icons";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import useRightDrawer from "../useRightDrawer";
import CustomizedSwitch from "../../UI/CustomizedSwitch";
import Zone from "./Zone";
import { SelectOptionModal } from "./SelectOptionModal";
import { ALPRModal } from "./ALPRModal";
import { SnapshotsModal } from "./SnapshotsModal";
import { MainContext } from "../../containers/HomePage/HomePage";
import { getBaseUrl } from "../../HelperFunctions";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { useRecoilState } from "recoil";
import { hasSnapshotRuleState } from "./Zone";
import { tab } from "@testing-library/user-event/dist/tab";
import { SelectRulesModal } from "./SelectRulesModal";
import RulesWizard from "./RulesWizard";

// ########################################################################################################
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 900,
      height: 500,
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

export interface LayoutProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactElement;
}

const useStyle = makeStyles({
  paper: {
    border: "2px solid #6D809D",
    color: "#15325F",
  },
});

const comments = [
  "Nuisance object detected",
  "Real object incorrectly detected in the image",
  "One or more real objects incorrectly detected in the image with large bounding box",
  "Incorrect object detection label",
  "No detection of objects at all",
  "Other",
];

const cameraBrands = [
  "Dahua",
  "Hikvision",
  "TruVision",
  "GeoVision-a",
  "GeoVision-b",
  "ProVision",
  "Uniview-camera",
  "Uniview-nvr",
  "Vivotek",
  "Axis",
  "Avigilon",
  "TVT-camera",
  "TVT-nvr/dvr",
  "AVTECH",
  "TeleEye",
];

const cameraRoutes = [
  {
    brand: "Dahua",
    route: "/cam/realmonitor?channel=1&subtype=1",
  },
  {
    brand: "Hikvision",
    route: "/streaming/channels/102",
  },
  {
    brand: "TruVision",
    route: " /streaming/channels/102",
  },
  {
    brand: "GeoVision-a",
    route: "/multicast/video2",
  },
  {
    brand: "GeoVision-b",
    route: "/CH002.sdp",
  },
  {
    brand: "ProVision",
    route: "/chID=1&streamType=sub&linkType=tcp",
  },
  {
    brand: "Uniview-camera",
    route: "/media/video2",
  },
  {
    brand: "Uniview-nvr",
    route: "/unicast/c1-1/s1/live",
  },
  {
    brand: "Vivotek",
    route: "/live2.sdp",
  },
  {
    brand: "Axis",
    route: " /axis-media/media.amp",
  },
  {
    brand: "Avigilon",
    route: "/defaultSecondary?mtu=1440&streamType=u",
  },
  {
    brand: "TVT-camera",
    route: "http://ip:554/profile1",
  },
  {
    brand: "TVT-nvr/dvr",
    route: "/?chID=1&streamtype=sub",
  },
  {
    brand: "AVTECH",
    route: "/live/video/ch02/profile2",
  },
  {
    brand: "TeleEye",
    route: "/profile2",
  },
];

export const SimpleModal = (props: LayoutProps) => {
  const classes = useStyles1();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const { children } = props;

  const body = (
    <div style={modalStyle} className={classes.paper}>
      {children}
    </div>
  );

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};
// ########################################################################################################

interface CameraMainDrawerProps {
  sensorhealth?: SensorHealth;
  siteHealthModal: (params: (number | string)[]) => void;
  interval: number;
  selectedItem: { [key: string]: any };
  mainMenu: MainMenu;
  showFullModalHandler: (params: (number | string)[]) => void;
  handleIntervalChange: (x: number) => void;
  currentOrg: Org;
  appStore?: AppStore;
  sensor: Sensor;
  site?: Site;
  setSelectedItem: (x: any) => void;
}

class initCanvasImage implements CanvasImage {
  width!: number;
  height!: number;
  stringb64!: string;
}

const CameraMainDrawer: React.FC<CameraMainDrawerProps> = ({
  sensorhealth,
  mainMenu,
  showFullModalHandler,
  siteHealthModal,
  selectedItem,
  interval,
  currentOrg,
  handleIntervalChange,
  appStore,
  sensor,
  site,
  setSelectedItem,
}: CameraMainDrawerProps) => {
  // CAMERA BRAND LOGIC
  const brandHandler = (event: any) => {
    if (config) {
      const changeStreamRoute = cameraRoutes.findIndex(
        (item) => item.brand === event?.target?.value
      );
      config.camera_brand = event?.target?.value;
      config.camera_rtsp_url = cameraRoutes[changeStreamRoute].route;
      setConfig(config);
      setRefresh(new Date().getTime());
    } else {
      const changeStreamRoute = cameraRoutes.findIndex(
        (item) => item.brand === event?.target?.value
      );
      let config_data: { [key: string]: any } = {};
      config_data["camera_rtsp_ip"] = "";
      config_data["camera_rtsp_port"] = "";
      config_data["camera_rtsp_password"] = "";
      config_data["camera_rtsp_url"] = cameraRoutes[changeStreamRoute].route;
      config_data["camera_rtsp_login"] = "";
      config_data["camera_http_port"] = "";
      config_data["camera_brand"] = event?.target?.value;
      setConfig(config_data);
    }
  };

  const [hd, indicateHd] = React.useState(false);
  const [advancedCheck, indicateAdvanced] = React.useState(false);
  const classes = useStyles();
  const [config, setConfig] = React.useState<{ [key: string]: any }>({});

  const [brand, setBrand] = useState<any>("");

  const [saved_motion_exclusion_zones, setSavedMotionExclusionZones] =
    React.useState("");
  const [messagemodalopen, setMessageModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [exclusionzones, setExclusionZones] = React.useState<{
    [key: string]: any;
  }>({});
  const [overrideexclusionzones1, setOverrideExclusionZones1] = React.useState<{
    [key: string]: any;
  }>({});
  const [overrideexclusionzones2, setOverrideExclusionZones2] = React.useState<{
    [key: string]: any;
  }>({});
  const [savedoverrideexclusionzones1, setSavedOverrideExclusionZones1] =
    React.useState("");
  const [savedoverrideexclusionzones2, setSavedOverrideExclusionZones2] =
    React.useState("");
  const [currentzone, setCurrentZone] = useState("PRIMARY");
  const [canvasimage, setCanvasImage] = React.useState<CanvasImage | undefined>(
    undefined
  );
  const [sensorWidth, setSensorWidth] = React.useState<number | undefined>(
    undefined
  );
  const [sensorHeight, setSensorHeight] = React.useState<number | undefined>(
    undefined
  );
  const [canvasSmtpImage, setCanvasSmtpImage] = React.useState<
    string | undefined
  >(undefined);
  const [canvasSmtpImageWidth, setCanvasSmtpImageWidth] = React.useState<
    string | undefined
  >(undefined);
  const [canvasSmtpImageHeight, setCanvasSmtpImageHeight] = React.useState<
    string | undefined
  >(undefined);
  const [name, setName] = useState(sensor.human_name);
  const [nameError, setNameError] = useState(false);
  const [talkbackMethod, setTalkbackMethod] = useState("");
  const [talkbackMethodError, setTalkbackMethodError] = useState(false);
  const [talkbackUrl, setTalkbackUrl] = useState("");
  const [talkbackUrlError, setTalkbackUrlError] = useState(false);
  const [talkbackUsername, setTalkbackUsername] = useState("");
  const [talkbackUsernameError, setTalkbackUsernameError] = useState(false);
  const [showWizard, setShowWizard] = useState(false);
  const [talkbackPassword, setTalkbackPassword] = useState("");
  const [talkbackPasswordError, setTalkbackPasswordError] = useState(false);
  const [classifier, setClassifier] = useState<Classifier | undefined>(
    undefined
  );
  const [dataOriginDefaults, setDataOriginDefaults] = useState<
    DataOriginElements | undefined
  >(undefined);
  const [sensorDefinedRules, setSensorDefinedRules] = useState<any | undefined>(
    []
  );
  const [popmessage, setPopMessage] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [message, setMessage] = React.useState<string>("");
  const [testmessage, setTestMessage] = React.useState<string>("");
  const [fullrtspurl, setFullRTSPUrl] = useState("");
  const [showrtspurl, setShowRtspUrl] = useState(false);
  const [showtestresult, setShowTestResult] = useState(false);
  const [testresult, setTestResult] = React.useState<
    { [key: string]: any } | undefined
  >(undefined);
  const [videotestresult, setVideoTestResult] = React.useState<
    { [key: string]: any } | undefined
  >(undefined);
  const [showping, setShowPing] = useState(false);
  const [showtelnet, setShowTelnet] = useState(false);
  const [showcurl, setShowCurl] = useState(false);
  const [timeentryset2, setTimeEntrySet2] = React.useState<
    TimeEntrySet | undefined
  >(undefined);
  const [timeentryset1, setTimeEntrySet1] = React.useState<
    TimeEntrySet | undefined
  >(undefined);
  const [tabvalue, setTabValue] = React.useState(0);

  const [streamurl, setStreamUrl] = React.useState("");
  const canvasref = useRef<DrawCanvas>(null);
  const [submitUpdate, setSubmitUpdate] = useState(true);
  const [camera_toggle_value, setCamera_toggle_value] = useState(false);
  const [resultFailure, setResultFailure] = useState(false);
  const [failMessage, setFailMessage] = useState("");
  const [smtpDetails, setSmtpDetails] = useState({
    success: false,
    edge_enhanced_smtp_server: "",
    edge_enhanced_smtp_server_port: "",
    smtp_password: "",
  });
  // const [dynDnsUsername, setDynDnsUsername] = React.useState<string>("");
  // const [dynDnsPassword, setDynDnsPassword] = React.useState<string>("");
  // const [dynDnsHelperText, setDynDnsHelperText] = React.useState<string>("");
  const currentEnv = useRef("None");
  const [displayEnv, setDisplayEnv] = useState("None");
  const [hasSnapshotRule] = useRecoilState(hasSnapshotRuleState);

  const environment_list = [
    "None",
    "Commercial - exterior",
    "Commercial - interior",
    "Commercial - perimeter",
    "Farm",
    "High site / tower",
    "Parking area",
    "Public space - pedestrian",
    "Rapid deployment tower",
    "Residential - exterior",
    "Residential - interior",
    "Residential - perimeter",
    "Rural",
    "Shopping mall - interior",
    "Other",
  ];

  // const [standardImage, setStandardImage] = useState(true);
  const [scaleFactor, setScaleFactor] = useState(1);

  //Hook
  const { activity, sensorhealthtimeline, healthtimeline, healthsites } =
    useRightDrawer(appStore, currentOrg, interval, selectedItem);

  const [channelID, setChannelID] = React.useState(0);

  const [serverSnapShots, setServerSnapshots] = React.useState<any[]>([]);

  // Function to enable Button click
  const isUpdatable = (isValid: boolean): boolean => {
    setSubmitUpdate(isValid);
    return isValid;
  };

  const ctx = React.useContext(MainContext);

  const [openWizardModal, setOpenWizardModal] = React.useState(false);
  const [openALPRModal, setOpenALPRModal] = React.useState(false);
  const [openSnapshotsModal, setOpenSnapshotsModal] = React.useState(false);
  const [optionValue, setOptionValue] = React.useState(undefined);

  let options = [
    "alert_zones",
    "alert_zones_and_counts",
    "suspicious_activity_self_storage",
    "Known Vehicle Filter",
    "Guard Tour",
    "Guard Management (no guard)",
    "PPE Detection - coming soon!",
    "Scene Change Alert",
    "unusual_activity",
    "Snapshot",
    "Tailgating - coming soon!",
    "ALPR - coming soon!",
    "Loitering - coming soon!",
    "Left object - coming soon!",
  ];

  let elevatedRoleOptions = [
    "Guard Tour",
    "Guard Management (no guard)",
    "Scene Change Alert",
    "unusual_activity",
    "Known Vehicle Filter",
    "suspicious_activity_self_storage",
  ];

  if (!site?.data_origin?.includes("mobcam")) {
    options = options.filter((item) => item !== "Snapshot");
  }

  var upcomingOptions = [
    "Tailgating - coming soon!",
    "Loitering - coming soon!",
    "Left object - coming soon!",
    "ALPR - coming soon!",
    "PPE Detection - coming soon!",
  ];

  if (
    appStore!.authService!.getRoles()!.indexOf("uat") === -1 &&
    appStore!.authService!.getRoles()!.indexOf("sys_admin") === -1
  ) {
    upcomingOptions.push(...elevatedRoleOptions);
  }

  if (
    appStore!.authService!.getRoles()!.indexOf("sys_admin") >= 0 ||
    appStore!.authService!.getRoles()!.indexOf("uat") >= 0
  ) {
    upcomingOptions = site?.data_origin?.includes("mobcam")
      ? [
          "suspicious_activity_self_storage", // TODO: currently not allowed on MobCam
          "Known Vehicle Filter", // TODO: currently not allowed on MobCam
          ...upcomingOptions,
        ]
      : upcomingOptions;
  }

  if (hasSnapshotRule) {
    upcomingOptions = [...upcomingOptions, "Snapshot"];
  }

  const isSMTPOrSentinel =
    site?.data_origin?.includes("smtp_client") ||
    site?.data_origin?.includes("sentinel");

  if (!isSMTPOrSentinel) {
    upcomingOptions = Array.from(
      new Set([
        ...upcomingOptions,
        "Guard Tour",
        "Guard Management (no guard)",
        "Scene Change Alert",
      ])
    );
  }
  // For SMTP PREMIUM PRODUCT, THIS METHOD FETCHES ALL SMTP DETAILS
  useEffect(() => {
    async function getSmtpDetails(siteId: number) {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        let newAlertSmtp =
          await appStore?.functionService.createNewSmtpPassword(
            token,
            siteId,
            false
          );
        if (newAlertSmtp?.success) {
          setSmtpDetails(newAlertSmtp);
        }
        setLoading(false);
      }
    }
    if (site?.data_origin === "alert_smtp_client") {
      getSmtpDetails(site.site_id);
    }
  }, [site]);

  const [updateConfigs, setUpdateConfigs] = React.useState(false);
  useEffect(() => {
    if (updateConfigs) {
      updateCameraConfig("other_tab");
      setUpdateConfigs(false);
    }
  }, [updateConfigs]);

  useEffect(() => {
    async function getSmtpDetails() {
      setLoading(true);
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        let response = await appStore?.functionService.fetchServerSnapshots(
          token,
          sensor.sensor_id
        );
        if (response?.success) {
          const data = [...response?.alerts, ...response?.inferences];
          // const data = [...inference_img, ...alert_img];
          // console.log("server snapshots multi -> ", data)
          setServerSnapshots(data);
        }
      }
      setLoading(false);
    }
    if (false) {
      getSmtpDetails();
    }
  }, [site]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (site && site.site_id) {
        setLoading(true);
        var token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          var alertchannelz: any[] =
            await appStore?.functionService.getAlertChannelsForSite(
              token,
              site.site_id
            );
          if (alertchannelz) {
            let ids_list = alertchannelz.map((channel) => {
              if (channel.site_id === site.site_id) {
                return channel.alert_channel_id;
              }
            });

            if (ids_list[0]) {
              setChannelID(ids_list[0]);
              ctx.setChannelId(ids_list[0]);
            }
          }
        }
        setLoading(false);
      }
    };
    fetchData();
  }, [site]);

  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        let hub_id_str = sensor.sensor_id_str.substring(
          0,
          sensor.sensor_id_str.indexOf("-")
        );

        const streamingURL =
          await appStore?.functionService.fetchStreamingBaseUrl(
            token,
            sensor.sensor_id_str
          );
        var url =
          streamingURL?.base_url +
          "/video_sensor?sensor_id_str=" +
          sensor.sensor_id_str +
          "&hub_id_str=" +
          hub_id_str +
          "&t=" +
          new Date().getTime() +
          "&jwt=" +
          token;
        setStreamUrl(url);
        if (sensor.environment && sensor.environment !== "") {
          currentEnv.current = sensor.environment;
        } else {
          currentEnv.current = "None";
        }
        sanityCheck();
      }
    };
    fetchData();
    if (sensor) {
      if (
        appStore &&
        (appStore.authService.getRoles().indexOf("sys_admin") >= 0 ||
          appStore.authService.getRoles().indexOf("site_admin") >= 0)
      ) {
        setTabValue(8);
      }
      if (sensor.meta && sensor.meta.talkback) {
        // talkbackMethod,
        // talkbackUrl,
        // talkbackUsername,
        // talkbackPassword,
        setTalkbackMethod(sensor.meta.talkback.talkbackMethod);
        setTalkbackUrl(sensor.meta.talkback.talkbackUrl);
        setTalkbackUsername(sensor.meta.talkback.talkbackUsername);
        setTalkbackPassword(sensor.meta.talkback.talkbackPassword);
      }
    }
  }, [site, sensor]);

  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        var thissensor = await appStore?.functionService.getSensor(
          token,
          sensor.sensor_id,
          "standard_image"
        );
        if (thissensor) {
          // setCanvasImage(thissensor.snapshot);
          setCanvasImage(thissensor.standard_image);
          // setStandardImage(true);
          if (thissensor?.snapshot_width && thissensor?.snapshot_height) {
            setSensorWidth(thissensor.snapshot_width);
            setSensorHeight(thissensor.snapshot_height);
            // setSensorWidth(thissensor.config.image_width);
            // setSensorHeight(thissensor.config.image_height);
          }
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [site, sensor]);

  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && sensor) {
        setLoading(true);
        var classifier = await appStore?.functionService.getClassifierForSite(
          token,
          sensor.site_id
        );
        setClassifier(classifier);
        setLoading(false);
      }
    };
    const fetchConfigData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && sensor) {
        setLoading(true);
        var defaults: DataOriginElements =
          await appStore?.functionService.getSiteDataOrigin(
            token,
            sensor.site_id
          );

        setDataOriginDefaults(defaults);
        setLoading(false);
      }
    };
    const fetchSensorDefinedRules = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && sensor) {
        setLoading(true);
        var response: any =
          await appStore?.functionService.getSensorDefinedRules(
            token,
            sensor.site_id
          );

        setSensorDefinedRules(response);
        setLoading(false);
      }
    };
    fetchSensorDefinedRules();
    fetchData();
    fetchConfigData();
  }, [site, sensor]);

  //sensor config from the db
  useEffect(() => {
    const fetchData = async () => {
      if (sensor) {
        setLoading(true);
        if (
          sensor.config &&
          Object.keys(sensor.config).length > 0 &&
          sensor.config.motion_exclusion_zones
        ) {
          //have a current config, extract the exclusion zones for display purposes.
          var newconfig = sensor.config;
          setTimeEntrySet1(
            new TimeEntrySet(newconfig.override_exclusion_days_and_times_1)
          );
          setTimeEntrySet2(
            new TimeEntrySet(newconfig.override_exclusion_days_and_times_2)
          );

          if (newconfig && newconfig.camera_type) {
            setCamera_toggle_value(newconfig.camera_type == "rtsp_h264");
          } else {
            newconfig["camera_type"] = "rtsp_h264";
          }

          setConfig(newconfig);

          let zones = getPolygons(sensor.config.motion_exclusion_zones);
          setExclusionZones(zones);
          setSavedMotionExclusionZones(sensor.config.motion_exclusion_zones);
          let zones1 = getPolygons(sensor.config.override_exclusion_zones_1);
          setOverrideExclusionZones1(zones1);
          setSavedOverrideExclusionZones1(
            sensor.config.override_exclusion_zones_1
          );
          let zones2 = getPolygons(sensor.config.override_exclusion_zones_2);
          setOverrideExclusionZones2(zones2);
          setSavedOverrideExclusionZones2(
            sensor.config.override_exclusion_zones_2
          );
        } else {
          //no current config, thus get default config for the data origin
          const token = await appStore?.authService.getAuthorisedToken();
          if (token && sensor) {
            const defaults: DataOriginElements =
              await appStore?.functionService.getSiteDataOrigin(
                token,
                sensor.site_id
              );
            if (defaults) {
              setConfig(defaults.sensor_defaults);
              setCanvasImage(new initCanvasImage());
              setDataOriginDefaults(defaults);
            }
          }
        }
        if (sensor.snapshot != null && sensor.snapshot != undefined) {
          setCanvasImage(sensor.snapshot);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, [site, sensor]);

  useEffect(() => {
    if (resultFailure) {
      const timeId = setTimeout(() => {
        // After 5 seconds set the show value to false

        setResultFailure(false);
        setFailMessage("");
      }, 5000);

      return () => {
        clearTimeout(timeId);
      };
    }
    // when the component is mounted, the alert is displayed for 5 seconds
  }, [resultFailure]);

  useEffect(() => {
    if (sensor.max_resolution == "HD") {
      indicateHd(true);
    }
  }, []);

  useEffect(() => {
    if (
      !site?.data_origin?.includes("motioncam") ||
      site?.data_origin?.includes("frontel") ||
      site?.data_origin?.includes("immix") ||
      site?.data_origin?.includes("ajax")
    ) {
      indicateAdvanced(true);
      ctx.setEnableAdvancedRules(true);
    }
  }, [sensor]);

  //Security model info:
  if (classifier && classifier.name == "security") {
    if (config) {
      let max_contour_area_fraction = ["0", "0", "0"];
      if (config.max_contour_area_fraction) {
        max_contour_area_fraction = config.max_contour_area_fraction
          ?.replace("[", "")
          ?.replace("]", "")
          ?.split(",");
      }
      let min_contour_area_fraction = ["0", "0", "0"];
      if (config.min_contour_area_fraction) {
        min_contour_area_fraction = config.min_contour_area_fraction
          ?.replace("[", "")
          ?.replace("]", "")
          ?.split(",");
      }
    }
  }

  const handleEnvironmentChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    var new_env = event.target.value as string;
    if (new_env === "Other") {
      var own_environment = prompt("Please enter a custom environment");
      new_env += " -> " + own_environment;
    }
    currentEnv.current = new_env;

    sanityCheck();
  };

  const sanityCheck = () => {
    if (currentEnv?.current?.includes("Other")) {
      setDisplayEnv("Other");
    } else if (currentEnv) {
      setDisplayEnv(currentEnv.current);
    } else {
      setDisplayEnv("None");
    }
  };

  const processSnapshot = (
    scale: number,
    imageValue: CanvasImage,
    me_zones: any,
    newconfig: { [key: string]: any }
  ): void => {
    if (
      (config.image_width == undefined || config.image_width == null) &&
      me_zones
    ) {
      me_zones = me_zones.replace("[[", "").replace("]]", "").split("],[");
      let str_new_me_zones = "";
      if (me_zones && me_zones.length > 0) {
        let str_new_polygon = "";
        me_zones.forEach((me_zone: any) => {
          let pairs = me_zone.split("),(");
          if (pairs && pairs.length > 0) {
            pairs.forEach((pair: string) => {
              let points = pair.split(",");
              let newstringpair = "";
              if (points.length == 2) {
                let x = Number(points[0]?.replace("(", ""));
                let y = Number(points[1]?.replace(")", ""));
                x = Math.round(x * scale);
                y = Math.round(y * scale);
                newstringpair = "(" + x + "," + y + ")";
                str_new_polygon = str_new_polygon + newstringpair + ",";
              }
            });
          }
          str_new_polygon =
            "[" +
            str_new_polygon.substring(0, str_new_polygon.length - 1) +
            "]";
          str_new_me_zones = str_new_me_zones + str_new_polygon + ",";
        });
        str_new_me_zones =
          "[" +
          str_new_me_zones.substring(0, str_new_me_zones.length - 1) +
          "]";
        newconfig.motion_exclusion_zones = str_new_me_zones;
        setConfig(newconfig);
        let zones = getPolygons(str_new_me_zones);
        if (currentzone === "PRIMARY") {
          setExclusionZones(zones);
        } else if (currentzone === "OVERRIDE1") {
          setOverrideExclusionZones1(zones);
        } else if (currentzone === "OVERRIDE2") {
          setOverrideExclusionZones2(zones);
        }
      }
    }

    setCanvasImage(imageValue);
    setLoading(false);
  };
  const getSnap = async (snap_type: string) => {
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token && sensor) {
      var newconfig = config;
      let me_zones = undefined;
      if (currentzone === "PRIMARY") {
        me_zones = config.motion_exclusion_zones;
      } else if (currentzone === "OVERRIDE1") {
        me_zones = config.override_exclusion_zones_1;
      } else if (currentzone === "OVERRIDE2") {
        me_zones = config.override_exclusion_zones_2;
      }

      var cimage =
        snap_type === "real_time"
          ? await appStore?.functionService.getSnapshot(token, sensor.sensor_id)
          : await appStore?.functionService.getSnapshotFromServer(
              token,
              sensor.sensor_id
            );
      var scale = 1;
      if (snap_type === "real_time") {
        if (
          cimage &&
          cimage.success &&
          config &&
          cimage.standard_image.stringb64
        ) {
          scale = cimage.standard_image.width / 500;
          // setStandardImage(true);
          setScaleFactor(cimage.scale_factor || 1);
          processSnapshot(scale, cimage.standard_image, me_zones, newconfig);
        } else {
          setPopMessage("Could not get snapshot!");
          setLoading(false);
          openMessageModal();
        }
      } else {
        if (cimage && cimage.success && config && cimage.standard_image) {
          scale = cimage.standard_image.width / 500;
          // setStandardImage(true);
          setScaleFactor(cimage.scale_factor || 1);
          processSnapshot(scale, cimage.standard_image, me_zones, newconfig);
        } else {
          setPopMessage("Could not get snapshot!");
          setLoading(false);
          openMessageModal();
        }
      }
    }
  };
  //MessageModal

  const openMessageModal = () => {
    setMessageModalOpen(true);
  };

  const closeMessageModal = () => {
    setMessageModalOpen(false);
  };
  const clickPingOpen = () => {
    setShowPing(!showping);
  };
  const clickTelnetOpen = () => {
    setShowTelnet(!showtelnet);
  };
  const clickCurlOpen = () => {
    setShowCurl(!showcurl);
  };

  /// Update Camera Function
  async function updateCameraMeta(
    sensor_id: number,
    met: { [key: string]: any }
  ) {
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let update_meta: UpdateSensorResult =
        await appStore?.functionService.modifySensorMeta(token, sensor_id, met);
      if (update_meta) {
        setLoading(false);
        if (update_meta.success) {
          // to be implemented
          setResultFailure(true);
          setFailMessage("talkback configured successfully.");
        } else {
          setResultFailure(true);
          setFailMessage(update_meta.msg);
        }
      }
    }
  }
  async function updateCameraSMTP(sensor_id: number, human_name: string) {
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let update_camera = await appStore?.functionService.modifySensorSMTP(
        token,
        sensor_id,
        human_name,
        currentEnv.current
      );
      //let hub_force = await appStore?.functionService.forceHubUpdate(token, hub_id);
      setLoading(false);
      if (update_camera) {
        if (update_camera.success) {
          //not forcing refresh here anymore as it takes too long for the hub to come back and you might
          //want to create another camera
          //uncomment before and below if needed to be implemented again

          //if (hub_force?.success) {
          //setPopMessage("Updated Camera " + human_name + " successfully and hub refresh message sent");
          //} else {
          setPopMessage("Updated Camera " + human_name + " successfully ");
          //}
          openMessageModal();
        } else {
          setPopMessage(update_camera.msg);
          openMessageModal();
        }
      }
    }
  }
  async function updateCamera(
    sensor_id: number,
    human_name: string,
    strconfig: string
  ) {
    // setLoading(false)
    // return
    setLoading(true);

    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let update_camera = await appStore?.functionService.modifySensor(
        token,
        sensor_id,
        human_name,
        strconfig,
        JSON.stringify(canvasimage!),
        currentEnv.current,
        config
      );

      //let hub_force = await appStore?.functionService.forceHubUpdate(token, hub_id);

      if (update_camera) {
        if (update_camera.success) {
          if (config) {
            setSavedMotionExclusionZones(config.motion_exclusion_zones);
            setSavedOverrideExclusionZones1(config.override_exclusion_zones_1);
            setSavedOverrideExclusionZones2(config.override_exclusion_zones_2);
          }
          //not forcing refresh here anymore as it takes too long for the hub to come back and you might
          //want to create another camera
          //uncomment before and below if needed to be implemented again

          //if (hub_force?.success) {
          //setPopMessage("Updated Camera " + human_name + " successfully and hub refresh message sent");
          //} else {
          setPopMessage("Updated Camera " + human_name + " successfully ");
          //}
          openMessageModal();
        } else {
          setPopMessage(update_camera.msg);
          openMessageModal();
        }
      }
      setLoading(false);
      setErrors({
        name: false,
        camera_brand: false,
        rtsp_ip: false,
        rtsp_url: false,
        rtsp_password: false,
        rtsp_port: false,
        rtsp_login: false,
        http_port: false,
      });
    }
  }
  const handleCameraSelection = (event: React.ChangeEvent<any>) => {
    if (config) {
      setCamera_toggle_value(event.target.value);
      config.camera_type = event.target.value;
      setConfig(config);
    } else {
      setCamera_toggle_value(event.target.value);
    }
  };
  const handleNameChange = (event: React.ChangeEvent<any>) => {
    setNameError(false);
    setName(event.target.value);
  };
  const handlePortChange = (event: React.ChangeEvent<any>) => {
    if (isNaN(event.target.value)) {
      setMessage("Port must be a valid number!");
    } else {
      if (config) {
        config.camera_rtsp_port = event.target.value;
        setConfig(config);
        setRefresh(new Date().getTime());
      } else {
        let config_data: { [key: string]: any } = {};
        config_data["camera_rtsp_ip"] = "";
        config_data["camera_rtsp_url"] = "";
        config_data["camera_rtsp_password"] = "";
        config_data["camera_rtsp_port"] = event.target.value;
        config_data["camera_rtsp_login"] = "";
        config_data["camera_http_port"] = "";
        config_data["camera_brand"] = "";
        setConfig(config_data);
      }
    }
  };

  const handleLoginChange = (event: React.ChangeEvent<any>) => {
    if (config) {
      config.camera_rtsp_login = event.target.value;
      setConfig(config);
      setRefresh(new Date().getTime());
    } else {
      let config_data: { [key: string]: any } = {};
      config_data["camera_rtsp_ip"] = "";
      config_data["camera_rtsp_url"] = "";
      config_data["camera_rtsp_password"] = "";
      config_data["camera_rtsp_port"] = "";
      config_data["camera_rtsp_login"] = event.target.value;
      config_data["camera_http_port"] = "";
      config_data["camera_brand"] = "";
      setConfig(config_data);
    }
  };
  const handleIPChange = (event: React.ChangeEvent<any>) => {
    if (config) {
      config.camera_rtsp_ip = event.target.value;
      setConfig(config);
      setRefresh(new Date().getTime());
    } else {
      let config_data: { [key: string]: any } = {};
      config_data["camera_rtsp_ip"] = event.target.value;
      config_data["camera_rtsp_url"] = "";
      config_data["camera_rtsp_password"] = "";
      config_data["camera_rtsp_port"] = "";
      config_data["camera_rtsp_login"] = "";
      config_data["camera_http_port"] = "";
      config_data["camera_brand"] = "";
      setConfig(config_data);
    }
  };

  const handleStreamChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (config) {
      config.camera_rtsp_url = event?.target?.value;
      setConfig(config);
      setRefresh(new Date().getTime());
    } else {
      let config_data: { [key: string]: any } = {};
      config_data["camera_rtsp_ip"] = "";
      config_data["camera_rtsp_port"] = "";
      config_data["camera_rtsp_password"] = "";
      config_data["camera_rtsp_url"] = event?.target?.value;
      config_data["camera_rtsp_login"] = "";
      config_data["camera_http_port"] = "";
      config_data["camera_brand"] = "";
      setConfig(config_data);
    }
  };

  const handleHTTPPort = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (config) {
      config.camera_http_port = event?.target?.value;
      setConfig(config);
      setRefresh(new Date().getTime());
    } else {
      let config_data: { [key: string]: any } = {};
      config_data["camera_rtsp_ip"] = "";
      config_data["camera_rtsp_port"] = "";
      config_data["camera_rtsp_password"] = "";
      config_data["camera_rtsp_url"] = "";
      config_data["camera_rtsp_login"] = "";
      config_data["camera_http_port"] = event?.target?.value;
      config_data["camera_brand"] = "";
      setConfig(config_data);
    }
  };

  const handelPasswordChange = (event: React.ChangeEvent<any>) => {
    if (config) {
      config.camera_rtsp_password = event.target.value;
      setConfig(config);
      setRefresh(new Date().getTime());
    } else {
      let config_data: { [key: string]: any } = {};
      config_data["camera_rtsp_ip"] = "";
      config_data["camera_rtsp_url"] = "";
      config_data["camera_rtsp_password"] = event.target.value;
      config_data["camera_rtsp_port"] = "";
      config_data["camera_rtsp_login"] = "";
      config_data["camera_http_port"] = "";
      config_data["camera_brand"] = "";
      setConfig(config_data);
    }
  };

  //receive data from completed polygon
  const onDataUpdate = (zones: any) => {
    if (currentzone === "PRIMARY") {
      setExclusionZones(zones);
      saveExclusionZones();
    } else if (currentzone === "OVERRIDE1") {
      setOverrideExclusionZones1(zones);
      saveExclusionZonesOverride1();
    } else if (currentzone === "OVERRIDE2") {
      setOverrideExclusionZones2(zones);
      saveExclusionZonesOverride2();
    }
  };
  const onFinishDraw = () => {
    //nothing todo has data comes through on onDataUpdate
  };
  //make si=ure start time is before end time for override exclusion zone schedule
  const validateTimeEntry = (timeentry: TimeEntry) => {
    let start = timeentry.startTime.split(":");
    let end = timeentry.endTime.split(":");
    if (parseInt(start[0]) > parseInt(end[0])) {
      return false;
    } else {
      if (parseInt(start[0]) === parseInt(end[0])) {
        if (parseInt(start[1]) > parseInt(end[1])) {
          return false;
        } else {
          if (parseInt(start[1]) === parseInt(end[1])) {
            if (parseInt(start[2]) > parseInt(end[2])) {
              return false;
            } else {
              return true;
            }
          }
        }
      }
    }
    return true;
  };
  const updateCameraSmtp = () => {
    if (name !== "") {
      updateCameraSMTP(sensor.sensor_id, name);
    } else {
      setNameError(true);
    }
  };

  const [errors, setErrors] = useState<any>({
    name: false,
    camera_brand: false,
    rtsp_ip: false,
    rtsp_url: false,
    rtsp_password: false,
    rtsp_port: false,
    rtsp_login: false,
    http_port: false,
  });

  const updateCameraConfig = (tabType: string = "") => {
    if (activeTab === "Settings" || activeTab === "Rtsp/Http Settings") {
      if (config && tabType !== "other_tab") {
        if (
          !site?.data_origin?.includes("mobcam") ||
          !site?.data_origin?.includes("client_hub")
        ) {
          if (
            !config.camera_rtsp_ip ||
            !config.camera_rtsp_url ||
            !config.camera_rtsp_password ||
            !config.camera_rtsp_port ||
            !config.camera_rtsp_login ||
            !config.camera_http_port ||
            !name ||
            !config.camera_brand
          ) {
            setPopMessage("Please complete all fields before submitting!");
            openMessageModal();
            setErrors({
              name: !name ? true : false,
              camera_brand: !config.camera_brand ? true : false,
              rtsp_ip: !config.camera_rtsp_ip ? true : false,
              rtsp_url: !config.camera_rtsp_url ? true : false,
              rtsp_password: !config.camera_rtsp_password ? true : false,
              rtsp_port: !config.camera_rtsp_port ? true : false,
              rtsp_login: !config.camera_rtsp_login ? true : false,
              http_port: !config.camera_http_port ? true : false,
            });
            return;
          }
        } else if (
          site?.data_origin?.includes("mobcam") ||
          site?.data_origin?.includes("client_hub")
        ) {
          if (
            !config.camera_rtsp_ip ||
            !config.camera_rtsp_url ||
            !config.camera_rtsp_password ||
            !config.camera_rtsp_port ||
            !config.camera_rtsp_login ||
            !name ||
            !config.camera_brand
          ) {
            setPopMessage("Please complete all fields before submitting!");
            openMessageModal();
            setErrors({
              name: !name ? true : false,
              camera_brand: !config.camera_brand ? true : false,
              rtsp_ip: !config.camera_rtsp_ip ? true : false,
              rtsp_url: !config.camera_rtsp_url ? true : false,
              rtsp_password: !config.camera_rtsp_password ? true : false,
              rtsp_port: !config.camera_rtsp_port ? true : false,
              rtsp_login: !config.camera_rtsp_login ? true : false,
            });
            return;
          }
        }
      }
    }
    //check if starttime < endtime for all time entries:
    let valid = true;
    let errormessage = "";
    if (timeentryset1) {
      timeentryset1.entries.forEach((timeEntry) => {
        if (!validateTimeEntry(timeEntry)) {
          valid = false;
          errormessage =
            errormessage +
            "Invalid start-end time for " +
            timeEntry.startTime +
            "-" +
            timeEntry.endTime +
            ", ";
        }
      });
    }
    if (timeentryset2) {
      timeentryset2.entries.forEach((timeEntry) => {
        if (!validateTimeEntry(timeEntry)) {
          valid = false;
          errormessage =
            errormessage +
            "Invalid start-end time for " +
            timeEntry.startTime +
            "-" +
            timeEntry.endTime +
            ", ";
        }
      });
    }
    if (valid) {
      var valid_days_and_times1 = "[";
      if (timeentryset1) {
        timeentryset1.entries.forEach((timeEntry) => {
          var thisentry =
            valid_days_and_times1 == "["
              ? timeEntry.timeString
              : "," + timeEntry.timeString;
          valid_days_and_times1 = valid_days_and_times1 + thisentry;
        });
      }
      valid_days_and_times1 = valid_days_and_times1 + "]";
      config.override_exclusion_days_and_times_1 = valid_days_and_times1;
      var valid_days_and_times2 = "[";
      if (timeentryset2) {
        timeentryset2.entries.forEach((timeEntry) => {
          var thisentry =
            valid_days_and_times2 == "["
              ? timeEntry.timeString
              : "," + timeEntry.timeString;
          valid_days_and_times2 = valid_days_and_times2 + thisentry;
        });
      }
      valid_days_and_times2 = valid_days_and_times2 + "]";
      config.override_exclusion_days_and_times_2 = valid_days_and_times2;
      var sensorconfig = appStore?.helpers.configFromDefault(
        dataOriginDefaults!.sensor_defaults!,
        config!
      );

      if (canvasimage && sensorconfig) {
        sensorconfig["image_width"] = canvasimage.width;
        sensorconfig["image_height"] = canvasimage.height;
      } else if (canvasimage) {
        sensorconfig = config;
        sensorconfig["image_width"] = canvasimage.width;
        sensorconfig["image_height"] = canvasimage.height;
        // sensorconfig = config;
        // sensorconfig["image_width"] = standardImage
        //   ? sensorWidth
        //   : canvasimage.width;
        // sensorconfig["image_height"] = standardImage
        //   ? sensorHeight
        //   : canvasimage.height;
      }
      if (showrtspurl && config) {
        let rtspurl = fullrtspurl;
        if (rtspurl.toLowerCase().startsWith("rtsp://")) {
          rtspurl = rtspurl.substring(7);
          let i = rtspurl.indexOf(":");
          if (i > 0) {
            sensorconfig!.camera_rtsp_login = rtspurl.substring(0, i);
            rtspurl = rtspurl.substring(i + 1);
            i = rtspurl.indexOf("@");
            if (i > 0) {
              sensorconfig!.camera_rtsp_password = rtspurl.substring(0, i);
              rtspurl = rtspurl.substring(i + 1);
              i = rtspurl.indexOf(":");
              if (i > 0) {
                sensorconfig!.camera_rtsp_ip = rtspurl.substring(0, i);
                rtspurl = rtspurl.substring(i + 1);
                i = rtspurl.indexOf("/");
                if (i > 0) {
                  if (isNaN(Number(rtspurl.substring(0, i)))) {
                  } else {
                    sensorconfig!.camera_rtsp_port = rtspurl.substring(0, i);
                    sensorconfig!.camera_rtsp_url = rtspurl.substring(i);
                  }
                }
              }
            }
          }
        }
      }
      sensorconfig!.camera_http_port = config?.camera_http_port;
      sensorconfig!.camera_brand = config?.camera_brand;
      var strconfig = JSON.stringify(sensorconfig);
      setMessage("");
      if (showrtspurl && config) {
        if (rtspToConfig()) {
          if (name === "") {
            setPopMessage("Please enter a human name for the camera !");
            openMessageModal();
          } else {
            updateCamera(sensor.sensor_id, name, strconfig);
          }
        } else {
          if (tabvalue === 4 && fullrtspurl === "") {
            config["camera_rtsp_url"] = "";
            config.camera_rtsp_ip = "";
            config.camera_rtsp_login = "";
            config.camera_brand = "";
            config.camera_rtsp_password = "";
            config.camera_rtsp_port = "";
            config.camera_http_port = "";
            sensorconfig!.camera_brand = "";
            sensorconfig!.camera_rtsp_url = "";
            sensorconfig!.camera_rtsp_ip = "";
            sensorconfig!.camera_rtsp_login = "";
            sensorconfig!.camera_rtsp_password = "";
            sensorconfig!.camera_rtsp_port = "";
            sensorconfig!.camera_http_port = "";
            let strcon = JSON.stringify(sensorconfig);
            // setPopMessage('All fields cleared !');
            // openMessageModal();
            updateCamera(sensor.sensor_id, name, strcon);
          } else {
            setPopMessage("Invalid rtsp format !");
            openMessageModal();
          }
        }
      } else {
        if (name === "") {
          setPopMessage("Please enter a human name for the camera !");
          openMessageModal();
        } else {
          if (config.camera_rtsp_url) {
            if (
              config.camera_rtsp_url.indexOf(":") >= 0 ||
              config.camera_rtsp_url.indexOf("@") >= 0 ||
              config.camera_rtsp_url.indexOf("rtsp") === 0
            ) {
              setPopMessage("Invalid stream route format !");
              openMessageModal();
            } else {
              if (
                config.override_exclusion_days_and_times_1 === "[]" &&
                config.override_exclusion_zones_1 !== "[]"
              ) {
                setPopMessage(
                  "Cannot update, please set schedule for Overrride 1 exclusion zones!"
                );
                openMessageModal();
              } else if (
                config.override_exclusion_days_and_times_1 !== "[]" &&
                config.override_exclusion_zones_1 === "[]"
              ) {
                setPopMessage(
                  "Cannot update, please either set exclusion zones or delete schedule for Overrride 1 !"
                );
                openMessageModal();
              } else {
                if (
                  config.override_exclusion_days_and_times_2 === "[]" &&
                  config.override_exclusion_zones_2 !== "[]"
                ) {
                  setPopMessage(
                    "Cannot update, please set schedule for Overrride 2 exclusion zones!"
                  );
                  openMessageModal();
                } else if (
                  config.override_exclusion_days_and_times_2 !== "[]" &&
                  config.override_exclusion_zones_2 === "[]"
                ) {
                  setPopMessage(
                    "Cannot update, please either set exclusion zones or delete schedule for Overrride 2 !"
                  );
                  openMessageModal();
                } else {
                  if (
                    config.motion_exclusion_zones === "[]" &&
                    config.override_exclusion_zones_2 === "[]" &&
                    config.override_exclusion_days_and_times_2 !== "[]"
                  ) {
                    setPopMessage(
                      "Cannot update, it does not make sense to have primary and overrride 2 exclusion zones blank, but have a schedule for override 2 exclusion zones!"
                    );
                    openMessageModal();
                  } else {
                    if (
                      config.motion_exclusion_zones === "[]" &&
                      config.override_exclusion_zones_1 === "[]" &&
                      config.override_exclusion_days_and_times_1 !== "[]"
                    ) {
                      setPopMessage(
                        "Cannot update, it does not make sense to have primary and overrride 1 exclusion zones blank, but have a schedule for override 1 exclusion zones!"
                      );
                      openMessageModal();
                    } else {
                      if (config.motion_exclusion_zones.indexOf("NaN") >= 0) {
                        setPopMessage(
                          "Cannot update, primary exclusion zone invalid!, Please clear and re-create"
                        );
                        openMessageModal();
                      } else {
                        if (
                          config.override_exclusion_zones_1.indexOf("NaN") >= 0
                        ) {
                          setPopMessage(
                            "Cannot update, override 1 exclusion zone invalid!, Please clear and re-create"
                          );
                          openMessageModal();
                        } else {
                          if (
                            config.override_exclusion_zones_2.indexOf("NaN") >=
                            0
                          ) {
                            setPopMessage(
                              "Cannot update, override 2 exclusion zone invalid!, Please clear and re-create"
                            );
                            openMessageModal();
                          } else {
                            updateCamera(sensor.sensor_id, name, strconfig);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          } else {
            if (
              config.override_exclusion_days_and_times_1 === "[]" &&
              config.override_exclusion_zones_1 !== "[]" &&
              config.override_exclusion_zones_1 !== undefined
            ) {
              setPopMessage(
                "Cannot update, please set schedule for Overrride 1 exclusion zones!"
              );
              openMessageModal();
            } else if (
              config.override_exclusion_days_and_times_1 !== "[]" &&
              config.override_exclusion_zones_1 === "[]"
            ) {
              setPopMessage(
                "Cannot update, please either set exclusion zones or delete schedule for Overrride 1 !"
              );
              openMessageModal();
            } else {
              if (
                config.override_exclusion_days_and_times_2 === "[]" &&
                config.override_exclusion_zones_2 !== "[]" &&
                config.override_exclusion_zones_2 !== undefined
              ) {
                setPopMessage(
                  "Cannot update, please set schedule for Overrride 2 exclusion zones!"
                );
                openMessageModal();
              } else if (
                config.override_exclusion_days_and_times_2 !== "[]" &&
                config.override_exclusion_zones_2 === "[]"
              ) {
                setPopMessage(
                  "Cannot update, please either set exclusion zones or delete schedule for Overrride 2 !"
                );
                openMessageModal();
              } else {
                if (
                  config.motion_exclusion_zones === "[]" &&
                  config.override_exclusion_zones_2 === "[]" &&
                  config.override_exclusion_days_and_times_2 !== "[]"
                ) {
                  setPopMessage(
                    "Cannot update, it does not make sense to have primary and overrride 2 exclusion zones blank, but have a schedule for override 2 exclusion zones!"
                  );
                  openMessageModal();
                } else {
                  if (
                    config.motion_exclusion_zones === "[]" &&
                    config.override_exclusion_zones_1 === "[]" &&
                    config.override_exclusion_days_and_times_1 !== "[]"
                  ) {
                    setPopMessage(
                      "Cannot update, it does not make sense to have primary and overrride 1 exclusion zones blank, but have a schedule for override 1 exclusion zones!"
                    );
                    openMessageModal();
                  } else {
                    if (
                      config.motion_exclusion_zones !== undefined &&
                      config.motion_exclusion_zones.indexOf("NaN") >= 0
                    ) {
                      setPopMessage(
                        "Cannot update, primary exclusion zone invalid!, Please clear and re-create"
                      );
                      openMessageModal();
                    } else {
                      if (
                        config.override_exclusion_zones_1 !== undefined &&
                        config.override_exclusion_zones_1.indexOf("NaN") >= 0
                      ) {
                        setPopMessage(
                          "Cannot update, override 1 exclusion zone invalid!, Please clear and re-create"
                        );
                        openMessageModal();
                      } else {
                        if (
                          config.override_exclusion_zones_1 !== undefined &&
                          config.override_exclusion_zones_2.indexOf("NaN") >= 0
                        ) {
                          setPopMessage(
                            "Cannot update, override 2 exclusion zone invalid!, Please clear and re-create"
                          );
                          openMessageModal();
                        } else {
                          updateCamera(sensor.sensor_id, name, strconfig);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      errormessage = errormessage.substring(0, errormessage.length - 2);
      setPopMessage(errormessage);
      openMessageModal();
    }
  };

  const clearExcusionZones = () => {
    if (currentzone === "PRIMARY") {
      setExclusionZones([]);
      saveExclusionZones();
    } else if (currentzone === "OVERRIDE1") {
      setOverrideExclusionZones1([]);
      saveExclusionZonesOverride1();
    } else if (currentzone === "OVERRIDE2") {
      setOverrideExclusionZones2([]);
      saveExclusionZonesOverride2();
    }
    canvasref.current?.cleanCanvas();
  };
  //create exclusion zones in cofig format. .. IMPORTANT! THIS DOES NOT SAVE TO THE DB
  const saveExclusionZones = () => {
    var motion_exclusion_zones = [];
    for (let key in exclusionzones) {
      if (key.startsWith("Polygon")) {
        let motion_exclusion_zone: string = "";
        var points: [] = exclusionzones[key];
        //Polygon has to have at least 3 points!
        if (points.length > 2) {
          points.forEach((point) => {
            if (point[1] != undefined) {
              var polypoint = "(" + point[0] + "," + point[1] + ")";
              motion_exclusion_zone = motion_exclusion_zone + polypoint + ",";
            }
          });
          motion_exclusion_zone = motion_exclusion_zone.substring(
            0,
            motion_exclusion_zone.length - 1
          );
          motion_exclusion_zones.push(motion_exclusion_zone);
        }
      }
    }
    var zones = "[";
    motion_exclusion_zones.forEach((me_zone) => {
      zones = zones + "[" + me_zone + "],";
    });
    if (zones.length > 1) {
      zones = zones.substring(0, zones.length - 1) + "]";
    } else {
      zones = zones + "]";
    }
    if (config) {
      config.motion_exclusion_zones = zones;
    }
    setConfig(config);
    setRefresh(new Date().getTime());
  };
  const saveExclusionZonesOverride1 = () => {
    var motion_exclusion_zones = [];
    for (let key in overrideexclusionzones1) {
      if (key.startsWith("Polygon")) {
        let motion_exclusion_zone: string = "";
        var points: [] = overrideexclusionzones1[key];
        //Polygon has to have at least 3 points!
        if (points.length > 2) {
          points.forEach((point) => {
            if (point[1] != undefined) {
              var polypoint = "(" + point[0] + "," + point[1] + ")";
              motion_exclusion_zone = motion_exclusion_zone + polypoint + ",";
            }
          });
          motion_exclusion_zone = motion_exclusion_zone.substring(
            0,
            motion_exclusion_zone.length - 1
          );
          motion_exclusion_zones.push(motion_exclusion_zone);
        }
      }
    }
    var zones = "[";
    motion_exclusion_zones.forEach((me_zone) => {
      zones = zones + "[" + me_zone + "],";
    });
    if (zones.length > 1) {
      zones = zones.substring(0, zones.length - 1) + "]";
    } else {
      zones = zones + "]";
    }
    if (config) {
      config.override_exclusion_zones_1 = zones;
    }
    setConfig(config);
    setRefresh(new Date().getTime());
  };
  const saveExclusionZonesOverride2 = () => {
    var motion_exclusion_zones = [];
    for (let key in overrideexclusionzones2) {
      if (key.startsWith("Polygon")) {
        let motion_exclusion_zone: string = "";
        var points: [] = overrideexclusionzones2[key];
        //Polygon has to have at least 3 points!
        if (points.length > 2) {
          points.forEach((point) => {
            if (point[1] != undefined) {
              var polypoint = "(" + point[0] + "," + point[1] + ")";
              motion_exclusion_zone = motion_exclusion_zone + polypoint + ",";
            }
          });
          motion_exclusion_zone = motion_exclusion_zone.substring(
            0,
            motion_exclusion_zone.length - 1
          );
          motion_exclusion_zones.push(motion_exclusion_zone);
        }
      }
    }
    var zones = "[";
    motion_exclusion_zones.forEach((me_zone) => {
      zones = zones + "[" + me_zone + "],";
    });
    if (zones.length > 1) {
      zones = zones.substring(0, zones.length - 1) + "]";
    } else {
      zones = zones + "]";
    }

    if (config) {
      config.override_exclusion_zones_2 = zones;
    }
    setConfig(config);
    setRefresh(new Date().getTime());
  };
  //update config from the "Advanced settings" Tab
  const updateConfig = (config: { [key: string]: any }) => {
    setConfig(config);
  };
  const handleFullRTSPUrlChange = (event: React.ChangeEvent<any>) => {
    setFullRTSPUrl(event.target.value);
    setMessage("");
  };
  const handleShowRtspUrl = (event: React.ChangeEvent<any>) => {
    if (showrtspurl) {
      rtspToConfig();
    } else {
      if (config) {
        let connectstr =
          "rtsp://" +
          config.camera_rtsp_login +
          ":" +
          config.camera_rtsp_password +
          "@" +
          config.camera_rtsp_ip +
          ":" +
          config.camera_rtsp_port +
          config.camera_rtsp_url;
        setFullRTSPUrl(connectstr);
      }
    }
    setShowRtspUrl(!showrtspurl);
  };

  //Camera video stream test
  const handleTest = async () => {
    setTestMessage("");
    setMessage("");
    setTestResult(undefined);
    setVideoTestResult(undefined);
    if (config) {
      setShowTestResult(true);
      let connectstr = "";
      if (showrtspurl) {
        connectstr = fullrtspurl;
      } else {
        connectstr =
          "rtsp://" +
          config.camera_rtsp_login +
          ":" +
          config.camera_rtsp_password +
          "@" +
          config.camera_rtsp_ip +
          ":" +
          config.camera_rtsp_port +
          config.camera_rtsp_url;
      }
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        let hub_id_str = sensor.sensor_id_str.substring(
          0,
          sensor.sensor_id_str.indexOf("-")
        );
        if (hub_id_str) {
          var video_info = await appStore?.functionService.getVideoInfo(
            token,
            hub_id_str,
            connectstr
          );
          if (video_info && video_info.success && video_info.info) {
            if (video_info.info.error) {
              setTestMessage(
                "Could not run camera stream test on your hub/camera! " +
                  video_info.info.error
              );
            } else {
              setVideoTestResult(video_info.info.result);

              setRefresh(new Date().getTime());
            }
          } else {
            setTestMessage(
              "Could not run camera stream test on your hub/camera!"
            );
          }
        }
      }
    }
  };

  const handleCurrentZoneSelection = (event: React.ChangeEvent<any>) => {
    setCurrentZone(event.target.value);
  };
  //Camera Networking test
  const handleAdvancedTest = async () => {
    let valid = true;
    setTestResult(undefined);
    setMessage("");
    setTestMessage("");
    setVideoTestResult(undefined);
    if (showrtspurl && config) {
      if (!rtspToConfig()) {
        valid = false;
        setMessage("Invalid RTSP format!");
      }
    }
    setShowTestResult(true);
    var token = await appStore?.authService.getAuthorisedToken();
    if (token && valid && config) {
      let hub_id_str = sensor.sensor_id_str.substring(
        0,
        sensor.sensor_id_str.indexOf("-")
      );
      if (hub_id_str) {
        let connection_str = "";
        if (showrtspurl) {
          connection_str = fullrtspurl;
        } else {
          connection_str =
            "rtsp://" +
            config.camera_rtsp_login +
            ":" +
            config.camera_rtsp_password +
            "@" +
            config.camera_rtsp_ip +
            ":" +
            config.camera_rtsp_port +
            config.camera_rtsp_url;
        }

        var advanced_tests = await appStore?.functionService.getAdvancedTests(
          token,
          hub_id_str,
          config.camera_rtsp_ip,
          Number(config.camera_rtsp_port),
          connection_str
        );
        if (advanced_tests && advanced_tests.success && advanced_tests.info) {
          setTestResult(advanced_tests.info);
          setRefresh(new Date().getTime());
        } else {
          setTestMessage("Could not run network testing on your hub/camera!");
        }
      }
    }
  };
  const rtspToConfig = () => {
    let valid = true;
    let rtspurl = fullrtspurl;
    if (rtspurl.toLowerCase().startsWith("rtsp://")) {
      rtspurl = rtspurl.substring(7);
      let i = rtspurl.indexOf(":");
      if (i > 0) {
        config!.camera_rtsp_login = rtspurl.substring(0, i);
        rtspurl = rtspurl.substring(i + 1);
        i = rtspurl.indexOf("@");
        if (i > 0) {
          config!.camera_rtsp_password = rtspurl.substring(0, i);
          rtspurl = rtspurl.substring(i + 1);
          i = rtspurl.indexOf(":");
          if (i > 0) {
            config!.camera_rtsp_ip = rtspurl.substring(0, i);
            rtspurl = rtspurl.substring(i + 1);
            i = rtspurl.indexOf("/");
            if (i > 0) {
              if (isNaN(Number(rtspurl.substring(0, i)))) {
                valid = false;
              } else {
                config!.camera_rtsp_port = rtspurl.substring(0, i);
                config!.camera_rtsp_url = rtspurl.substring(i);
                if (
                  config!.camera_rtsp_url.indexOf(":") >= 0 ||
                  config!.camera_rtsp_url.indexOf("@") >= 0
                ) {
                  valid = false;
                }
              }
            } else {
              valid = false;
            }
          } else {
            valid = false;
          }
        } else {
          valid = false;
        }
      } else {
        valid = false;
      }
    } else {
      valid = false;
    }
    return valid;
  };
  //get polygons from confic format zones
  const getPolygons = (zones: string) => {
    zones = zones?.replace("[[", "");
    zones = zones?.replace("]]", "");
    var polygonsdict: { [key: string]: [number[]] } = {};
    var polygons = zones.split("],[");
    var count = 0;
    polygons.forEach((polygon) => {
      var points = polygon.split("),(");
      var pointsarray: any = [];
      points.forEach((point) => {
        point = point?.replace("(", "");
        point = point?.replace(")", "");
        var coords = point.split(",");
        var coordsarray = [];
        coordsarray.push(Number(coords[0]));
        coordsarray.push(Number(coords[1]));
        pointsarray.push(coordsarray);
      });
      polygonsdict["Polygon_" + count] = pointsarray;
      count++;
    });
    return polygonsdict;
  };
  //creating zebra striped table rows
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    })
  )(TableRow);
  const StyledTableRowSMTP = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        display: "flex",
        justifyContent: "space-between",
        textAlign: "left",
      },
    })
  )(TableRow);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const [activeTab, setActiveTab] = useState<string>("");

  const getActiveTab = (label: any) => {
    setActiveTab(label);
  };

  const changeTimeEntry = (entrySet: TimeEntrySet | undefined) => {
    if (currentzone === "OVERRIDE1") {
      setTimeEntrySet1(entrySet);
    } else if (currentzone === "OVERRIDE2") {
      setTimeEntrySet2(entrySet);
    }
    //if deleted last one, make sure to add add button
    if (entrySet?.entries.length == 0) {
      setRefresh(new Date().getTime());
    }
  };
  const addSchedule = () => {
    if (currentzone === "OVERRIDE1") {
      if (timeentryset1) {
        timeentryset1.addTimeEntry();
        setTimeEntrySet1(timeentryset1);
      } else {
        const newtimeentrySet = new TimeEntrySet("");
        newtimeentrySet.addTimeEntry();
        setTimeEntrySet1(newtimeentrySet);
      }
    } else if (currentzone === "OVERRIDE2") {
      if (timeentryset2) {
        timeentryset2.addTimeEntry();
        setTimeEntrySet2(timeentryset2);
      } else {
        const newtimeentrySet = new TimeEntrySet("");
        newtimeentrySet.addTimeEntry();
        setTimeEntrySet2(newtimeentrySet);
      }
    }
    setRefresh(new Date().getTime());
    window.scrollTo(0, document.body.scrollHeight);
  };

  var schedule: React.ReactNode[] = [];
  if (currentzone === "OVERRIDE1") {
    if (timeentryset1 && timeentryset1.entries.length > 0) {
      schedule.push(
        <TimeEntryPanel
          horisontal={true}
          key={currentzone}
          addicon={true}
          noEndTime={false}
          disabled={false}
          entrySet={timeentryset1}
          changeTimeEntryParent={(entrySet: TimeEntrySet | undefined) =>
            changeTimeEntry(entrySet)
          }
        ></TimeEntryPanel>
      );
    } else {
      schedule.push(
        <div className={classes.form_component}>
          <div className={classes.bold_text}>
            Add Schedule
            <IconButton
              edge="end"
              aria-label="add"
              onClick={() => {
                addSchedule();
              }}
            >
              <AddCircle color={"primary"} />
            </IconButton>
          </div>
        </div>
      );
    }
  } else if (currentzone === "OVERRIDE2") {
    if (timeentryset2 && timeentryset2.entries.length > 0) {
      schedule.push(
        <TimeEntryPanel
          horisontal={true}
          key={currentzone}
          addicon={true}
          disabled={false}
          entrySet={timeentryset2}
          noEndTime={false}
          changeTimeEntryParent={(entrySet: TimeEntrySet | undefined) =>
            changeTimeEntry(entrySet)
          }
        ></TimeEntryPanel>
      );
    } else {
      schedule.push(
        <div className={classes.form_component}>
          <div className={classes.bold_text}>
            Add Schedule
            <IconButton
              edge="end"
              aria-label="add"
              onClick={() => {
                addSchedule();
              }}
            >
              <AddCircle color={"primary"} />
            </IconButton>
          </div>
        </div>
      );
    }
  }

  let hub_id_str = sensor.sensor_id_str.substring(
    0,
    sensor.sensor_id_str.indexOf("-")
  );
  var advanced = [];
  if (
    //test
    (classifier?.name == "security" && config) ||
    site?.data_origin?.includes("motion") ||
    site?.data_origin?.includes("mobcam")
  ) {
    advanced.push(
      <SecurityAdvanced
        isValid={isUpdatable}
        editable={true}
        securityconfig={config}
        setSecurityConfig={updateConfig}
      />
    );
  } else if (
    (classifier?.name == "PPE Detection - coming soon!" && config) ||
    (site && site.data_origin && site?.data_origin.includes("objectcam"))
  ) {
    advanced.push(
      <SafetyAdvanced
        editable={true}
        safetyconfig={config}
        setSafetyConfig={updateConfig}
      />
    );
  } else if (classifier?.name == "weapons" && config) {
    advanced.push(
      <WeaponAdvanced
        editable={true}
        weaponconfig={config}
        setWeaponConfig={updateConfig}
      />
    );
  }

  //Create the test results when the data available
  var testbody: React.ReactNode[] = [];
  if (showtestresult) {
    var pingbody: React.ReactNode[] = [];
    var videobody: React.ReactNode[] = [];
    var telnetbody: React.ReactNode[] = [];
    var curlbody: React.ReactNode[] = [];
    let pingsuccess = false;
    let telnetsuccess = false;
    let curlsuccess = false;

    if (testresult != undefined) {
      for (let key in testresult) {
        let result = testresult[key];
        if (key.toLowerCase().indexOf("ping") >= 0) {
          if (showping) {
            pingbody.push(
              <StyledTableRow>
                <TableCell>
                  <Typography className={classes.bold_text}>{key}</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.bold_text}>
                    {result}
                  </Typography>
                </TableCell>
              </StyledTableRow>
            );
          }
          if (result.indexOf("bytes from") >= 0) {
            pingsuccess = true;
          }
        }
        if (key.toLowerCase().indexOf("telnet") >= 0) {
          if (showtelnet) {
            telnetbody.push(
              <StyledTableRow>
                <TableCell>
                  <Typography className={classes.bold_text}>{key}</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.bold_text}>
                    {result}
                  </Typography>
                </TableCell>
              </StyledTableRow>
            );
          }
          if (result.indexOf("Connected to") >= 0) {
            telnetsuccess = true;
          }
        }
        if (key.toLowerCase().indexOf("curl") >= 0) {
          if (showcurl) {
            curlbody.push(
              <StyledTableRow>
                <TableCell>
                  <Typography className={classes?.bold_text}>{key}</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes?.bold_text}>
                    {result}
                  </Typography>
                </TableCell>
              </StyledTableRow>
            );
          }
          if (result.indexOf("left intact") >= 0) {
            curlsuccess = true;
          }
        }
      }
    }
    if (videotestresult != undefined) {
      for (let key in videotestresult) {
        let result = videotestresult[key];
        videobody.push(
          <StyledTableRow>
            <TableCell>
              <Typography className={classes.bold_text}>{key}</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.bold_text}>{result}</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.bold_text}>{""}</Typography>
            </TableCell>
          </StyledTableRow>
        );
      }
    }

    if (videotestresult) {
      testbody.push(<>{videobody}</>);
    } else if (testresult) {
      testbody.push(
        <>
          <StyledTableRow>
            <TableCell>
              <Typography className={classes.bold_text}>
                {"PING COMMAND"}
              </Typography>
            </TableCell>
            <TableCell>
              {pingsuccess ? (
                <CheckIcon style={{ fill: "#3f54a5" }} />
              ) : (
                <CloseIcon style={{ fill: "#ee4623" }} />
              )}
            </TableCell>
            <TableCell>
              {showping ? (
                <ExpandMoreIcon onClick={() => clickPingOpen()} />
              ) : (
                <ExpandLessIcon onClick={() => clickPingOpen()} />
              )}
            </TableCell>
          </StyledTableRow>
          {pingbody}
          <StyledTableRow>
            <TableCell>
              <Typography className={classes.bold_text}>
                {"TELNET COMMAND"}
              </Typography>
            </TableCell>
            <TableCell>
              {telnetsuccess ? (
                <CheckIcon style={{ fill: "#3f54a5" }} />
              ) : (
                <CloseIcon style={{ fill: "#ee4623" }} />
              )}
            </TableCell>
            <TableCell>
              {showtelnet ? (
                <ExpandMoreIcon onClick={() => clickTelnetOpen()} />
              ) : (
                <ExpandLessIcon onClick={() => clickTelnetOpen()} />
              )}
            </TableCell>
          </StyledTableRow>
          {telnetbody}
          <StyledTableRow>
            <TableCell>
              <Typography className={classes.bold_text}>
                {"CURL COMMAND"}
              </Typography>
            </TableCell>
            <TableCell>
              {curlsuccess ? (
                <CheckIcon style={{ fill: "#3f54a5" }} />
              ) : (
                <CloseIcon style={{ fill: "#ee4623" }} />
              )}
            </TableCell>
            <TableCell>
              {showcurl ? (
                <ExpandMoreIcon onClick={() => clickCurlOpen()} />
              ) : (
                <ExpandLessIcon onClick={() => clickCurlOpen()} />
              )}
            </TableCell>
          </StyledTableRow>
          {curlbody}
        </>
      );
    } else {
      if (testmessage === "") {
        testbody.push(
          <StyledTableRow>
            <TableCell> </TableCell>
            <TableCell>
              <div className={classes.floatleft}>
                <svg width="48px" height="48px" viewBox="0 0 128 128">
                  <g>
                    <linearGradient id="linear-gradient">
                      <stop offset="0%" stop-color="#ffffff" />
                      <stop offset="100%" stop-color="#22305f" />
                    </linearGradient>
                    <path
                      d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                      fill="url(#linear-gradient)"
                      fill-rule="evenodd"
                    />
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      from="0 64 64"
                      to="360 64 64"
                      dur="1080ms"
                      repeatCount="indefinite"
                    ></animateTransform>
                  </g>
                </svg>
              </div>
            </TableCell>
            <TableCell> </TableCell>
          </StyledTableRow>
        );
      } else {
        testbody.push(
          <StyledTableRow>
            <TableCell>
              <Typography className={classes.bold_error_text}>
                {"ERROR:"}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.bold_error_text}>
                {testmessage}
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </StyledTableRow>
        );
      }
    }
  }
  var noteUpdate: React.ReactNode[] = [];
  noteUpdate.push(
    <div
      className={classes.medium_panel}
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        textAlign: "left",
      }}
    >
      <Typography
        className={classes.bold_text}
        style={{ fontSize: 14, marginRight: 15, color: "red" }}
      >
        Please avoid the following characters for the password :
      </Typography>
      <Typography
        className={classes.bold_text}
        style={{ fontSize: 14, color: "black" }}
      >
        // @ :
      </Typography>
    </div>
  );
  var updateButton: React.ReactNode[] = [];
  // if (tabvalue !== 3 && tabvalue !== 0) {
  updateButton.push(
    <div className={classes.button_container}>
      {submitUpdate && (
        <Button
          variant="contained"
          className={classes.cancel_button_top_margin}
          onClick={() => updateCameraConfig("update_tab")}
          disabled={!submitUpdate}
        >
          <span className={classes.bold_text}>Update</span>
        </Button>
      )}
      {!submitUpdate && (
        <Button
          variant="contained"
          className={classes.cancel_button_top_margin_disabled}
          onClick={() => updateCameraConfig("update_tab")}
          disabled={!submitUpdate}
        >
          <span className={classes.bold_text}>Update</span>
        </Button>
      )}
    </div>
  );
  // }
  var instruction: React.ReactNode[] = [];
  instruction.push(
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "left" }}
    >
      {/* <span className={classes.bold_text}>
        <br></br>Click on image to create exclusion zones.
      </span>
      <br></br> */}
      {currentzone === "PRIMARY" ? (
        <Typography>
          <span className={classes.bold_text}>
            The default zone is used when no primary or secondary fields are set
          </span>
        </Typography>
      ) : null}
      {currentzone === "OVERRIDE1" ? (
        <Typography>
          <span className={classes.bold_text}>
            If schedule is set for the primary zone, an exclusion zone must also
            be set.
          </span>
        </Typography>
      ) : null}
      {currentzone === "OVERRIDE2" ? (
        <Typography>
          <span className={classes.bold_text}>
            If schedule is set for the secondary zone, an exclusion zone must
            also be set.
          </span>
        </Typography>
      ) : null}
    </div>
  );

  var card: React.ReactNode[] = [];
  if (
    canvasimage &&
    config &&
    canvasimage &&
    canvasimage.stringb64 &&
    canvasimage.stringb64 != ""
  ) {
    var drawcanvas: React.ReactNode[] = [];
    if (currentzone === "PRIMARY" && config?.motion_exclusion_zones) {
      drawcanvas.push(
        <DrawCanvas
          showlabels
          clearExcusionZones={clearExcusionZones}
          key={currentzone}
          ref={canvasref}
          imgSrc={"data:image/png;base64," + canvasimage.stringb64}
          tool={"Polygon"}
          width={canvasimage.width}
          height={canvasimage.height}
          brushSize={2}
          color={"black"}
          onFinishDraw={onFinishDraw}
          onDataUpdate={onDataUpdate}
          initialData={getPolygons(config.motion_exclusion_zones)}
          savedData={getPolygons(saved_motion_exclusion_zones)}
        />
      );
    } else if (currentzone === "OVERRIDE1") {
      drawcanvas.push(
        <DrawCanvas
          showlabels
          clearExcusionZones={clearExcusionZones}
          key={currentzone}
          ref={canvasref}
          imgSrc={"data:image/png;base64," + canvasimage.stringb64}
          tool={"Polygon"}
          width={canvasimage.width}
          height={canvasimage.height}
          brushSize={2}
          color={"black"}
          onFinishDraw={onFinishDraw}
          onDataUpdate={onDataUpdate}
          initialData={getPolygons(config.override_exclusion_zones_1)}
          savedData={getPolygons(savedoverrideexclusionzones1)}
        />
      );
    } else if (currentzone === "OVERRIDE2") {
      drawcanvas.push(
        <DrawCanvas
          showlabels
          clearExcusionZones={clearExcusionZones}
          key={currentzone}
          ref={canvasref}
          imgSrc={"data:image/png;base64," + canvasimage.stringb64}
          tool={"Polygon"}
          width={canvasimage.width}
          height={canvasimage.height}
          brushSize={2}
          color={"black"}
          onFinishDraw={onFinishDraw}
          onDataUpdate={onDataUpdate}
          initialData={getPolygons(config.override_exclusion_zones_2)}
          savedData={getPolygons(savedoverrideexclusionzones2)}
        />
      );
    }
    let primary = " (Not Set)";
    if (config.motion_exclusion_zones !== "[]") {
      primary = " (Set)";
    }
    let override1 = " (Not Set)";
    if (
      config.override_exclusion_zones_1 !== "[]" ||
      config.override_exclusion_days_and_times_1 !== "[]"
    ) {
      override1 = " (Set)";
    }
    let override2 = " (Not Set)";
    if (
      config.override_exclusion_zones_2 !== "[]" ||
      config.override_exclusion_days_and_times_2 !== "[]"
    ) {
      override2 = " (Set)";
    }
    card.push(
      <>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card
              className={classes.card_container}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  width: "100%",
                  fontSize: 14,
                  textAlign: "center",
                  margin: "auto",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
                className={classes.bold_text}
              >
                Click on image to create exclusion zones.
              </p>
              {drawcanvas}
              <br />
              {/* {sensorWidth && sensorHeight && (
                <p
                  style={{
                    width: "100%",
                    fontSize: 14,
                    textAlign: "center",
                    margin: "0 auto",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                >
                  Your camera resolution is {sensorWidth} x {sensorHeight}
                </p>
              )} */}
              {/* <p style={{
                     width: "100%",
                     fontSize: 14,
                     textAlign: "center",
                     margin: "auto",
                     marginBottom: "10px",
                     marginTop: "10px",
                   }}
                 >
                <Card className={classes.icon_container}>
                  <Tooltip placement="bottom" title="Clear exclusion zones">
                    <IconButton
                      edge="end"
                      aria-label="add"
                      onClick={clearExcusionZones}
                    >
                      <DeleteOutlineIcon color={"primary"} />
                    </IconButton>
                  </Tooltip>
                </Card>
              </p> */}
              {/* <div style={{ margin: "0 auto", width: 200 }}>
                <Button
                  variant="outlined"
                  onClick={clearExcusionZones}
                  style={{
                    fontWeight: 500,
                    fontSize: 13,
                    borderRadius: 4,
                    height: 41,
                    marginLeft: 30,
                    marginBottom: 20,
                  }}
                  color="primary"
                  component="span"
                >
                  Clear Zones
                </Button>
              </div> */}
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} style={{ width: "100%" }}>
              <div className={classes.instruction_text}>{instruction}</div>
            </Grid>
            <Grid container spacing={2}>
              <FormControl
                component="fieldset"
                fullWidth
                variant="outlined"
                className={classes.margin25}
              >
                <FormLabel component="legend">Exclusion Zone</FormLabel>
                <RadioGroup
                  value={currentzone}
                  onChange={handleCurrentZoneSelection}
                  row
                >
                  <FormControlLabel
                    value="PRIMARY"
                    control={<Radio color="default" />}
                    label={"Default" + primary}
                  />
                  <FormControlLabel
                    value="OVERRIDE1"
                    control={<Radio color="default" />}
                    label={"Timed Override Primary" + override1}
                  />
                  <FormControlLabel
                    value="OVERRIDE2"
                    control={<Radio color="default" />}
                    label={"Timed Override Secondary" + override2}
                  />
                </RadioGroup>
              </FormControl>

              {/* <Card className={classes.icon_container}>
                <Tooltip placement="bottom" title="Take snapshot">
                  <IconButton
                    edge="end"
                    aria-label="add"
                    onClick={() => {
                      if (
                        site?.data_origin === "alert_smtp_client" ||
                        site?.data_origin === "sentinel_http_edge_human1" ||
                        site?.data_origin === "immix_ailink_human1"
                      ) {
                        getSnap("server");
                      } else {
                        getSnap("real_time");
                      }
                    }}
                  >
                    <CameraIcon color={"primary"} />
                  </IconButton>
                </Tooltip>
                <Tooltip placement="bottom" title="Save exclusion zones">
                  <IconButton
                    edge="end"
                    aria-label="add"
                    onClick={updateCameraConfig}
                  >
                    <SaveIcon color={"primary"} />
                  </IconButton>
                </Tooltip>
                <Tooltip placement="bottom" title="Clear exclusion zones">
                  <IconButton
                    edge="end"
                    aria-label="add"
                    onClick={clearExcusionZones}
                  >
                    <DeleteOutlineIcon color={"primary"} />
                  </IconButton>
                </Tooltip>
              </Card> */}

              {/* start */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <CustomButton
                  disabled={false}
                  title={"Fetch Latest Image"}
                  onClickHandler={() => {
                    if (
                      site?.data_origin === "alert_smtp_client" ||
                      site?.data_origin === "sentinel_http_edge_human1" ||
                      site?.data_origin === "immix_ailink_human1"
                    ) {
                      getSnap("server");
                    } else {
                      getSnap("real_time");
                    }
                  }}
                  id="take"
                />
                {/* <CustomButton
                  disabled={false}
                  title={"Clear exclusion zones"}
                  onClickHandler={clearExcusionZones}
                  id="clear"
                /> */}
                <CustomButton
                  disabled={false}
                  title={"Select Reference Image"}
                  onClickHandler={() => setOpenSnapshotsModal(true)}
                  id="clear"
                />
                <CustomButton
                  disabled={false}
                  title={"Save Settings"}
                  onClickHandler={() => updateCameraConfig("other_tab")}
                  id="save"
                />
              </div>
              {/* end */}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  } else {
    card.push(
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <Card className={classes.card_container}>
            <img
              className={classes.placeholder}
              src="/assets/img/snapshot.png"
              alt=""
            />
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Tooltip placement="bottom" title="Take snapshot">
            <IconButton
              edge="end"
              aria-label="add"
              onClick={() => {
                if (
                  site?.data_origin === "alert_smtp_client" ||
                  site?.data_origin === "sentinel_http_edge_human1" ||
                  site?.data_origin === "immix_ailink_human1"
                ) {
                  getSnap("server");
                } else {
                  getSnap("real_time");
                }
              }}
            >
              <CameraIcon color={"primary"} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }
  var body: React.ReactNode[] = [];
  const styler = useStyle();

  if (showrtspurl) {
    body.push(
      <TextField
        label="Full RTSP URL"
        onChange={handleFullRTSPUrlChange}
        value={fullrtspurl}
        variant="outlined"
        margin="normal"
        fullWidth
        InputProps={{
          className: classes.bold_text,
        }}
        InputLabelProps={{
          classes: {
            root: classes.bold_text,
          },
        }}
      />
    );
  } else {
    body.push(
      <>
        <TextField
          error={errors?.rtsp_ip}
          required
          fullWidth
          label="IP Address"
          onChange={handleIPChange}
          value={config?.camera_rtsp_ip}
          variant="outlined"
          margin="normal"
          helperText={errors?.rtsp_ip ? "IP Address is required" : ""}
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            classes: {
              root: classes.bold_text,
            },
          }}
        />
        <FormControl
          fullWidth={true}
          variant="outlined"
          style={{ margin: "15px 0 10px 0" }}
          error={errors?.camera_brand}
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            className={classes.bold_text}
          >
            Camera Brand
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            // value={brand}
            value={config?.camera_brand}
            // onChange={handleBrandHandler}
            onChange={brandHandler}
            label="Environment"
            // style={{ width: 300 }}
          >
            {cameraBrands.map((option: string) => (
              <MenuItem value={option}>{option}</MenuItem>
            ))}
          </Select>
          <FormHelperText
            style={{
              color: "red",
            }}
          >
            {errors?.camera_brand ? "Please select a valid camera brand" : ""}
          </FormHelperText>
        </FormControl>
        <TextField
          error={errors?.rtsp_url}
          required
          fullWidth
          label="Stream Route"
          onChange={handleStreamChange}
          value={config?.camera_rtsp_url}
          variant="outlined"
          margin="normal"
          helperText={errors?.rtsp_url ? "Stream Route is required" : ""}
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            shrink: true,
            classes: {
              root: classes.bold_text,
            },
          }}
          placeholder="Enter Stream Route" // Add a placeholder here
        />
        {site?.data_origin?.includes("mobcam") ||
        site?.data_origin?.includes("client_hub") ? (
          ""
        ) : (
          <TextField
            error={errors?.http_port}
            required
            fullWidth
            label="HTTP Port"
            onChange={handleHTTPPort}
            value={config?.camera_http_port}
            variant="outlined"
            margin="normal"
            helperText={errors?.http_port ? "HTTP Port is required" : ""}
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.bold_text,
              },
            }}
            placeholder="Enter HTTP Port" // Add a placeholder here
          />
        )}
        <TextField
          error={errors?.rtsp_port}
          required
          fullWidth
          label="RTSP Port"
          onChange={handlePortChange}
          value={config?.camera_rtsp_port}
          variant="outlined"
          margin="normal"
          helperText={errors?.rtsp_port ? "RTSP Port is required" : ""}
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            classes: {
              root: classes.bold_text,
            },
          }}
        />
        <TextField
          error={errors?.rtsp_login}
          required
          fullWidth
          label="RTSP Login"
          onChange={handleLoginChange}
          value={config?.camera_rtsp_login}
          variant="outlined"
          margin="normal"
          helperText={errors?.rtsp_login ? "RTSP Login is required" : ""}
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            classes: {
              root: classes.bold_text,
            },
          }}
        />
        <TextField
          error={errors?.rtsp_password}
          required
          fullWidth
          label="RTSP Password"
          type="password"
          value={config?.camera_rtsp_password}
          onChange={handelPasswordChange}
          variant="outlined"
          margin="normal"
          helperText={errors?.rtsp_password ? "RTSP Password is required" : ""}
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            classes: {
              root: classes.bold_text,
            },
          }}
        />
      </>
    );
  }
  var frametab: React.ReactNode[] = [];
  if (tabvalue === 0) {
    frametab.push(
      <>
        {openSnapshotsModal && (
          <SnapshotsModal
            openModal={openSnapshotsModal}
            onContinue={(e) => {
              let obj = { ...e };
              setCanvasImage(obj.standard_image);
              setOpenSnapshotsModal(false);
              setUpdateConfigs(true);
              // setStandardImage(true);
              setScaleFactor(obj.scale_factor);
            }}
            site={site}
            sensor={sensor}
            appStore={appStore}
            onClose={() => setOpenSnapshotsModal(false)}
          />
        )}
        {card}
        <br />
        <br />
        {schedule}
      </>
    );
  }

  var detailsTable: React.ReactNode[] = [];
  if (sensor) {
    let meta: { [key: string]: string } = {};
    meta = sensor.meta;
    if (meta["sensor_email_key"]) {
      let splitArr = meta["sensor_email_key"].split("|");
      splitArr.map((item, index) => {
        detailsTable.push(
          <StyledTableRowSMTP>
            <TableCell>
              <Typography className={classes.bold_text}>
                {item.split(":")[0]}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.bold_text}>
                {item.split(":")[1]}
              </Typography>
            </TableCell>
          </StyledTableRowSMTP>
        );
      });
    }
  }

  var sentinnelAndAlertRestSettings: React.ReactNode[] = [];
  if (tabvalue === 1) {
    if (
      site?.data_origin === "alert_rest_client" ||
      site?.data_origin === "sentinel_http_edge_human1"
    ) {
      sentinnelAndAlertRestSettings.push(
        <div className={classes.medium_panel}>
          <br></br>
          <Typography className={classes.bold_error_text}>{message}</Typography>
          <TextField
            label="Camera Human Name"
            onChange={handleNameChange}
            value={name}
            // error={nameError}
            error={errors?.name}
            variant="outlined"
            margin="normal"
            helperText={errors?.name ? "Camera Human Name required" : ""}
            fullWidth
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          />
          <br></br>
          <FormControl fullWidth={true} variant="outlined">
            <InputLabel
              id="demo-simple-select-outlined-label"
              className={classes.bold_text}
            >
              Select Environment
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={displayEnv}
              onChange={handleEnvironmentChange}
              label="Environment"
              // style={{ width: 300 }}
            >
              {environment_list.map((option: string) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <br></br>
          <Typography className={classes.bold_text_top_margin}>
            Hub for this camera: {hub_id_str}
          </Typography>
        </div>
      );
    }
  }

  var smtpConnectionDetailsTab: React.ReactNode[] = [];
  if (tabvalue === 1) {
    if (site?.data_origin === "alert_smtp_client") {
      smtpConnectionDetailsTab.push(
        <div className={classes.medium_panel}>
          <br></br>
          <Typography className={classes.bold_error_text}>{message}</Typography>
          <TextField
            label="Camera Human Name"
            onChange={handleNameChange}
            value={name}
            // error={nameError}
            error={errors?.name}
            variant="outlined"
            margin="normal"
            helperText={errors?.name ? "Camera Human Name required" : ""}
            fullWidth
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          />
          <br></br>
          <FormControl fullWidth={true} variant="outlined">
            <InputLabel
              id="demo-simple-select-outlined-label"
              className={classes.bold_text}
            >
              Select Environment
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={displayEnv}
              onChange={handleEnvironmentChange}
              label="Environment"
              // style={{ width: 300 }}
            >
              {environment_list.map((option: string) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <br></br>
          <div
            style={{
              boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
              marginTop: 15,
              borderRadius: 15,
              overflow: "hidden",
              width: "100%",
            }}
          >
            <TableContainer>{detailsTable}</TableContainer>
          </div>

          <div className={classes.button_container}>
            <Button
              variant="contained"
              className={classes.cancel_button_top_margin}
              onClick={updateCameraSmtp}
            >
              <span className={classes.bold_text}>Update</span>
            </Button>
          </div>
        </div>
      );
    }
  }
  var smtpSettingsTab: React.ReactNode[] = [];
  if (tabvalue === 2) {
    if (site?.data_origin === "alert_smtp_client") {
      smtpSettingsTab.push(
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            marginLeft: "15%",
            marginRight: "15%",
          }}
        >
          <Typography className={classes.smtpSettingsTabHeader}>
            <span
              className={classes.bold_text}
              style={{ fontSize: 15, color: "primary" }}
            >
              Site SMTP Connection Details
            </span>
          </Typography>
          <br></br>
          <div
            style={{
              boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
              marginTop: 15,
              borderRadius: 15,
              overflow: "hidden",
              width: "100%",
            }}
          >
            <TableContainer>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text_billing}>
                    Sender name:{" "}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    className={classes.bold_text}
                    style={{ color: "grey" }}
                  >
                    DeepAlert
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text_billing}>
                    Sender email address:{" "}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    className={classes.bold_text}
                    style={{ color: "grey" }}
                  >
                    alert@deepalert.ai
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text_billing}>
                    SMTP server:{" "}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    className={classes.bold_text}
                    style={{ color: "grey" }}
                  >
                    {smtpDetails.edge_enhanced_smtp_server}
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text_billing}>
                    SMTP PORT:{" "}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    className={classes.bold_text}
                    style={{ color: "grey" }}
                  >
                    {smtpDetails.edge_enhanced_smtp_server_port}
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text_billing}>
                    SMTP username:{" "}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    className={classes.bold_text_billing}
                    style={{ color: "grey" }}
                  >
                    {sensor.sensor_id_str.split("-")[0]}
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text_billing}>
                    SMTP password:{" "}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    className={classes.bold_text}
                    style={{ color: "grey" }}
                  >
                    {smtpDetails.smtp_password}
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
              <StyledTableRowSMTP>
                <TableCell>
                  <Typography className={classes.bold_text_billing}>
                    Recipient email address:{" "}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    className={classes.bold_text}
                    style={{ color: "grey" }}
                  >
                    cloud@deepalert.ai
                  </Typography>
                </TableCell>
              </StyledTableRowSMTP>
            </TableContainer>
          </div>
        </div>
      );
    }
  }
  var talkbackTab: React.ReactNode[] = [];
  if (tabvalue === 5) {
    talkbackTab.push(
      <div className={classes.medium_panel}>
        <TextField
          label="talkback method"
          onChange={(event: React.ChangeEvent<any>) => {
            if (event.target.value === "") {
              setTalkbackMethodError(true);
              setTalkbackMethod(event.target.value);
            } else {
              setTalkbackMethodError(false);
              setTalkbackMethod(event.target.value);
            }
          }}
          value={talkbackMethod}
          variant="outlined"
          margin="normal"
          fullWidth
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            classes: {
              root: classes.bold_text,
            },
          }}
          error={talkbackMethodError}
        />
        <TextField
          label="talkback url"
          onChange={(event: React.ChangeEvent<any>) => {
            if (
              event.target.value === "" ||
              !event.target.value.startsWith("http")
            ) {
              setTalkbackUrlError(true);
              setTalkbackUrl(event.target.value);
            } else {
              setTalkbackUrlError(false);
              setTalkbackUrl(event.target.value);
            }
          }}
          value={talkbackUrl}
          variant="outlined"
          margin="normal"
          fullWidth
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            classes: {
              root: classes.bold_text,
            },
          }}
          error={talkbackUrlError}
          helperText={talkbackUrlError && "url should start with http"}
        />
        <TextField
          label="talkback username"
          onChange={(event: React.ChangeEvent<any>) => {
            if (event.target.value === "") {
              setTalkbackUsernameError(true);
              setTalkbackUsername(event.target.value);
            } else {
              setTalkbackUsernameError(false);
              setTalkbackUsername(event.target.value);
            }
          }}
          value={talkbackUsername}
          variant="outlined"
          margin="normal"
          fullWidth
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            classes: {
              root: classes.bold_text,
            },
          }}
          error={talkbackUsernameError}
        />
        <TextField
          label="talkback password"
          onChange={(event: React.ChangeEvent<any>) => {
            if (event.target.value === "") {
              setTalkbackPasswordError(true);
              setTalkbackPassword(event.target.value);
            } else {
              setTalkbackPasswordError(false);
              setTalkbackPassword(event.target.value);
            }
          }}
          value={talkbackPassword}
          variant="outlined"
          margin="normal"
          fullWidth
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            classes: {
              root: classes.bold_text,
            },
          }}
          error={talkbackPasswordError}
        />
        {resultFailure && (
          <Box mt={2} mb={2} justifyContent="center">
            <Alert
              variant="outlined"
              severity={
                failMessage === "talkback configured successfully."
                  ? "success"
                  : "error"
              }
              onClose={() => {
                setResultFailure(false);
                setFailMessage("");
              }}
            >
              {failMessage}
            </Alert>
          </Box>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            className={classes.cancel_button_top_margin}
            style={{ alignSelf: "center" }}
            variant="contained"
            onClick={() => {
              if (
                !talkbackMethodError &&
                !talkbackUrlError &&
                !talkbackUsernameError &&
                !talkbackPasswordError
              ) {
                let meta: { [key: string]: any } = {};
                meta = sensor.meta;
                meta["talkback"] = {
                  talkbackMethod,
                  talkbackUrl,
                  talkbackUsername,
                  talkbackPassword,
                };
                updateCameraMeta(sensor.sensor_id, meta);
              } else {
                setFailMessage("at least one of the fields is invalid");
                setResultFailure(true);
              }
            }}
          >
            <span className={classes.bold_text}>Configure talkback</span>
          </Button>
        </div>
      </div>
    );
  }

  var settingstab: React.ReactNode[] = [];
  if (tabvalue === 1 || tabvalue === 4) {
    settingstab.push(
      <div className={classes.medium_panel}>
        <br></br>
        <Typography className={classes.bold_error_text}>{message}</Typography>
        <TextField
          // error={nameError}
          error={errors?.name}
          label="Camera Human Name"
          onChange={handleNameChange}
          value={name}
          variant="outlined"
          margin="normal"
          helperText={errors?.name ? "Camera Human Name required" : ""}
          fullWidth
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            classes: {
              root: classes.bold_text,
            },
          }}
        />
        <br></br>
        <FormControl fullWidth={true} variant="outlined">
          <InputLabel
            id="demo-simple-select-outlined-label"
            className={classes.bold_text}
          >
            Select Environment
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={displayEnv}
            onChange={handleEnvironmentChange}
            label="Environment"
            // style={{ width: 300 }}
          >
            {environment_list.map((option: string) => (
              <MenuItem value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <br></br>
        {site?.data_origin !== "" ? (
          <>
            <Typography className={classes.bold_text_top_margin}>
              Hub for this camera: {hub_id_str}
            </Typography>
            <Typography className={classes.bold_text_top_margin}>
              Camera type
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="rtsp_h264"
              name="radio-buttons-group"
              row
              value={config.camera_type}
              onChange={handleCameraSelection}
            >
              <FormControlLabel
                value="rtsp_h264"
                control={<Radio />}
                color="primary"
                label={
                  <Typography className={classes.bold_text}>
                    rtsp_h264
                  </Typography>
                }
              />
              <FormControlLabel
                value="rtsp_h265"
                control={<Radio />}
                color="primary"
                label={
                  <Typography className={classes.bold_text}>
                    rtsp_h265
                  </Typography>
                }
              />
            </RadioGroup>
            {/* <div className={classes.radio_container}>
              <Typography className={classes.bold_text_top_margin}>
                RTSP H264
              </Typography>
              <Radio
                onChange={handleCameraSelection}
                // value="rtsp_h264"
                value={config.camera_type}
                color="primary"
                checked={camera_toggle_value}
              />
              <Typography className={classes.bold_text_top_margin}>
                RTSP H265
              </Typography>
              <Radio
                onChange={handleCameraSelection}
                value="rtsp_h265"
                color="primary"
                checked={camera_toggle_value}
              />
            </div> */}
            <ToggleButtonGroup
              className={classes.form_component}
              exclusive
              value={showrtspurl}
              onChange={handleShowRtspUrl}
            >
              <ToggleButton value={false}>RTSP Details</ToggleButton>
              <ToggleButton value={true}>RTSP URL</ToggleButton>
            </ToggleButtonGroup>
            {body}
          </>
        ) : null}
      </div>
    );
  }

  var advancedtab: React.ReactNode[] = [];
  if (tabvalue === 2) {
    advancedtab.push(<>{advanced}</>);
  }

  var testtab: React.ReactNode[] = [];
  if (tabvalue === 3) {
    testtab.push(
      <div className={classes.medium_panel}>
        <br></br>
        <Typography className={classes.bold_text}>
          Camera Test Result
        </Typography>
        <Typography className={classes.bold_error_text}>{message}</Typography>
        <TableContainer component={Paper}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Response</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>Detail</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{testbody}</TableBody>
          </Table>
        </TableContainer>
        <div className={classes.button_container}>
          <Button
            className={classes.cancel_button_top_margin}
            variant="contained"
            onClick={handleTest}
          >
            <span className={classes.bold_text}>TEST CAMERA STREAM</span>
          </Button>
          <Button
            className={classes.cancel_button_top_margin}
            variant="contained"
            onClick={handleAdvancedTest}
          >
            <span className={classes.bold_text}>TEST CAMERA NETWORK</span>
          </Button>
        </div>
      </div>
    );
  }
  var streamtab: React.ReactNode[] = [];
  if (tabvalue === 4) {
    streamtab.push(
      <div className={classes.medium_panel}>
        <br></br>
        {/* <label>testing live stream</label> */}

        <>
          <ToggleButtonGroup
            className={classes.form_component}
            exclusive
            value={showrtspurl}
            onChange={handleShowRtspUrl}
          >
            <ToggleButton value={false}>RTSP Details</ToggleButton>
            <ToggleButton value={true}>RTSP URL</ToggleButton>
          </ToggleButtonGroup>
          {body}
        </>
      </div>
    );
  }

  let isSiteAdmin = false;
  let isSysAdmin = false;
  let isAdvancedRulesAdmin = false;
  let commonDataOrigin =
    site?.data_origin === "sentinel_http_edge_human1" ||
    site?.data_origin === "alert_smtp_client" ||
    site?.data_origin === "client_hub_mobcam_human1" ||
    site?.data_origin === "immix_ailink_human1" ||
    site?.data_origin === "client_hub_objectcam_weapons" ||
    site?.data_origin === "client_hub_objectcam_safety";

  if (appStore && appStore.authService && appStore.authService.getRoles()) {
    if (appStore.authService.getRoles().indexOf("site_admin") >= 0) {
      isSiteAdmin = true;
      isAdvancedRulesAdmin = true;
    }
    if (appStore.authService.getRoles().indexOf("sys_admin") >= 0) {
      isSysAdmin = true;
    }
    if (appStore.authService.getRoles().indexOf("advanced_rules_admin") >= 0) {
      isAdvancedRulesAdmin = true;
    }
  }

  // var tabsmenu: React.ReactNode[] = [];
  // if (isSiteAdmin) {
  //   if (
  //     sensor?.advanced_rules_enabled === false ||
  //     sensor?.advanced_rules_enabled === null
  //   ) {
  //     // tabsmenu.push(<Tab value={0} label="Refference Image" />);
  //   } else if (sensor?.advanced_rules_enabled === true) {
  //   }
  //   tabsmenu.push(<Tab value={0} label="Reference Image" />);
  //   (isSysAdmin || isAdvancedRulesAdmin) &&
  //     isSiteAdmin &&
  //     // sensor.advanced_rules_enabled &&
  //     site?.data_origin &&
  //     !site?.data_origin.includes("motioncam") &&
  //     tabsmenu.push(<Tab value={8} label="Rules" />);
  //   if (site?.data_origin === "alert_smtp_client") {
  //     tabsmenu.push(<Tab value={1} label="Camera Identifier" />);
  //     tabsmenu.push(<Tab value={2} label="SMTP details" />);
  //     tabsmenu.push(<Tab value={4} label="Rtsp/Http Settings" />);
  //     // tabsmenu.push(<Tab value={5} label="Talkback Settings" />);
  //   } else if (
  //     site?.data_origin === "alert_rest_client" ||
  //     site?.data_origin === "sentinel_http_edge_human1"
  //   ) {
  //     tabsmenu.push(<Tab value={1} label="Camera Identifier" />);
  //     tabsmenu.push(<Tab value={4} label="Rtsp/Http Settings" />);
  //     // tabsmenu.push(<Tab value={5} label="Talkback Settings" />);
  //   } else if (
  //     site?.data_origin?.includes("motioncam") ||
  //     site?.data_origin?.includes("mobcam") ||
  //     site?.data_origin?.includes("objectcam")
  //   ) {
  //     tabsmenu.push(<Tab value={1} label="Settings" />);
  //     tabsmenu.push(<Tab value={2} label="Advanced Settings" />);
  //     tabsmenu.push(<Tab value={3} label="Tests" />);
  //     // tabsmenu.push(<Tab value={5} label="Talkback Settings" />);
  //   } else {
  //     tabsmenu.push(<Tab value={1} label="Settings" />);
  //     // tabsmenu.push(<Tab value={5} label="Talkback Settings" />);
  //   }
  // } else {
  //   tabsmenu.push(<Tab value={0} label="Reference Image" />);

  //   tabsmenu.push(<Tab value={1} label="Settings" />);
  //   // tabsmenu.push(<Tab value={5} label="Talkback Settings" />);

  //   if (site?.data_origin === "alert_smtp_client") {
  //   } else if (
  //     (site && site.data_origin && site?.data_origin.includes("motioncam")) ||
  //     (site && site.data_origin && site?.data_origin.includes("mobcam")) ||
  //     (site && site.data_origin && site?.data_origin.includes("objectcam"))
  //   ) {
  //     tabsmenu.push(<Tab value={2} label="Advanced Settings" />);
  //     // tabsmenu.push(<Tab value={5} label="Talkback Settings" />);
  //   }
  // }

  // if (isSysAdmin && commonDataOrigin) {
  //   tabsmenu.push(<Tab value={6} label="HD" />);
  // }

  //////////////////////////////////////////////////////////
  var tabsMenuData = [];
  if (isSiteAdmin) {
    if (
      sensor?.advanced_rules_enabled === false ||
      sensor?.advanced_rules_enabled === null
    ) {
      // tabsmenu.push(<Tab value={0} label="Refference Image" />);
    } else if (sensor?.advanced_rules_enabled === true) {
    }
    tabsMenuData.push({ value: 0, label: "Reference Image" });
    (isSysAdmin || isAdvancedRulesAdmin) &&
      isSiteAdmin &&
      // sensor.advanced_rules_enabled &&
      site?.data_origin &&
      !site?.data_origin.includes("motioncam") &&
      tabsMenuData.push({ value: 8, label: "Rules" });
    if (site?.data_origin === "alert_smtp_client") {
      tabsMenuData.push({ value: 1, label: "Camera Identifier" });
      tabsMenuData.push({ value: 2, label: "SMTP details" });
      tabsMenuData.push({ value: 4, label: "Rtsp/Http Settings" });
      // tabsmenu.push(<Tab value={5} label="Talkback Settings" />);
    } else if (
      site?.data_origin === "alert_rest_client" ||
      site?.data_origin === "sentinel_http_edge_human1"
    ) {
      tabsMenuData.push({ value: 1, label: "Camera Identifier" });
      tabsMenuData.push({ value: 4, label: "Rtsp/Http Settings" });
    } else if (
      site?.data_origin?.includes("motioncam") ||
      site?.data_origin?.includes("mobcam") ||
      site?.data_origin?.includes("objectcam")
    ) {
      tabsMenuData.push({ value: 1, label: "Settings" });
      tabsMenuData.push({ value: 2, label: "Advanced Settings" });
      tabsMenuData.push({ value: 3, label: "Test" });
    } else {
      tabsMenuData.push({ value: 1, label: "Settings" });
    }
  } else {
    tabsMenuData.push({ value: 0, label: "Reference Image" });
    tabsMenuData.push({ value: 1, label: "Settings" });
    if (site?.data_origin === "alert_smtp_client") {
    } else if (
      (site && site.data_origin && site?.data_origin.includes("motioncam")) ||
      (site && site.data_origin && site?.data_origin.includes("mobcam")) ||
      (site && site.data_origin && site?.data_origin.includes("objectcam"))
    ) {
      tabsMenuData.push({ value: 2, label: "Advanced Settings" });
    }
  }

  if (isSysAdmin && commonDataOrigin) {
    tabsMenuData.push({ value: 6, label: "HD" });
  }

  const hdHandler = async (hdVal: boolean) => {
    let proceed = false;
    if (hdVal) {
      proceed = window.confirm(
        "WARNING!\n\nEnabling the HD camera setting requires additional processing and storage within " +
          "DeepAlert and may incur additional costs.\nNote that uploading HD images to DeepAlert may also affect " +
          "your bandwidth requirements.\n\nBy enabling this setting you are acknowledging your acceptance of " +
          "potential additional costs."
      );
    }
    if (proceed || !hdVal) {
      indicateHd(hdVal);
      const token = await appStore?.authService.getAuthorisedToken();
      const sensor_id = sensor.sensor_id;
      let max_res = hdVal ? "HD" : "VGA";
      if (token) {
        setLoading(true);
        try {
          const response = await appStore?.functionService.setHd(
            token,
            max_res,
            sensor_id
          );
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const handleChangeAuto = (value: any) => {
    if (!value) {
      return;
    }
    const val = { ...value };

    setOptionValue(value);
    ctx.setRuleType(value.fields.rule_type as string);
  };

  const handleChangeAuto2 = (value: any) => {
    if (!value) {
      return;
    }
    setOptionValue(value);
    ctx.setRuleType(value as string);
  };

  let hdSettingsTab = (
    <div
      style={{
        margin: "auto",
        marginTop: 50,
        justifyContent: "center",
        alignItems: "center",
        width: "80%",
        alignSelf: "center",
      }}
    >
      <Card style={{ alignSelf: "center", borderRadius: 8 }}>
        <div
          style={{
            display: "block",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            fontSize: 12,
            color: "#15325F",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <p>
            High Definition (HD) setting is used to achieve enhanced detections
            at longer ranges where the objects to be detected may be small.
            DeepAlert relies on identifying the class of object as part of its
            detection process which requires sufficient resolution. By default
            we process at resolution of up to VGA (640 x 480). With this option
            you can process HD up to 1920 x 1080.
            <br />
            <br />
            If enabling this option, ensure that your camera is configured to
            provide images with sufficient resolution to meet your detection
            requirements
          </p>
          <br />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            paddingBottom: 10,
          }}
        >
          <p className={classes.bold_text}>(VGA / HD) resolution</p>
          &nbsp; &nbsp;
          <CustomizedSwitch
            checked={hd}
            setChecked={(e: boolean) => hdHandler(e)}
            label=""
          />
        </div>
      </Card>
    </div>
  );
  // useEffect(() => {
  //   const DynDnsSettings = async () => {
  //     let dynDnsConfiguration = {
  //       username: "",
  //       password: "",
  //     };
  //     let dynDnsHelperMessage =
  //       "Please note that if you are using DeepAlert Dynamic DNS, you can use ";
  //     let domain = "";
  //     const token = await appStore?.authService.getAuthorisedToken();
  //     if (token) {
  //       if (sensor) {
  //         if (sensor.sensor_id) {
  //           let dynDnsResult =
  //             await appStore?.functionService.fetchDynDnsConfiguration(
  //               token,
  //               "sensor_id",
  //               sensor.sensor_id
  //             );
  //           if (
  //             dynDnsResult &&
  //             dynDnsResult.username &&
  //             dynDnsResult.password
  //           ) {
  //             dynDnsConfiguration.username = dynDnsResult.username;
  //             dynDnsConfiguration.password = dynDnsResult.password;
  //           }
  //         }
  //         let hubDetails = await appStore?.functionService.getHub(
  //           token,
  //           sensor.hub_id
  //         );
  //         if (hubDetails && hubDetails.hub_id_str) {
  //           domain = hubDetails.hub_id_str + "." + server_address;
  //           dynDnsHelperMessage += domain + " as the hostname";
  //         }
  //       }
  //     } else {
  //       dynDnsHelperMessage += "a pre-determined hostname";
  //     }
  //     setDynDnsUsername(dynDnsConfiguration.username);
  //     setDynDnsPassword(dynDnsConfiguration.password);
  //     setDynDnsHelperText(dynDnsHelperMessage);
  //   };
  //   DynDnsSettings();
  // });

  const saveZonesHandler = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      const request = JSON.stringify({
        sensor_id: sensor.sensor_id,
        site_id: site?.site_id,
        standard_image: JSON.stringify(ctx.snapshot).toString(),
        zone_names: ctx.zoneOptions.filter(
          (option: string) => option !== "full image"
        ),
        zone_coordinates: ctx.savedZones,
        standard_image_scale_factor: ctx.scaleFactor,
      });

      try {
        const baseUrl = getBaseUrl();
        let res: any = await fetch(baseUrl + "/set_advanced_alert_zones", {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = (await (await res).json()) as any;
        if (result) {
          msg.success("successfully saved zones");
        } else {
          msg.error("failed to save zones, please try again later");
        }
      } catch (err) {
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const addRule = ctx.zoneOptions.length === 0 ? true : false;
  let advancedRulesTab = (
    <div
      style={{
        margin: "auto",
        marginTop: 50,
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        alignSelf: "center",
      }}
    >
      <Card style={{ alignSelf: "center", borderRadius: 8 }}>
        <div
          style={{
            display: "block",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            fontSize: 12,
            color: "#15325F",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          {/* <p>
            High Definition (HD) setting is used to achieve enhanced detections
            at longer ranges where the objects to be detected may be small.
            DeepAlert relies on identifying the class of object as part of its
            detection process which requires sufficient resolution. By default
            we process at resolution of up to VGA (640 x 480). With this option
            you can process HD up to 1920 x 1080.
            <br />
            If enabling this option, ensure that your camera is configured to
            provide images with sufficient resolution to meet your detection
            requirements.
          </p> */}
          <br />
        </div>
        {openALPRModal && (
          <ALPRModal
            openModal={openALPRModal}
            onContinue={() => {
              handleChangeAuto("Known Vehicle Filter");
              setOpenALPRModal(false);
              setShowWizard(true);
              setOpenWizardModal(false);
            }}
            onClose={() => setOpenALPRModal(false)}
          />
        )}
        {!showWizard && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              paddingBottom: 5,
            }}
          >
            {/* <SelectOptionModal
              openModal={openWizardModal}
              title={"Select Type of Rule"}
              isSMTPOrSentinel={isSMTPOrSentinel}
              onContinue={() => {
                setShowWizard(true);
                setOpenWizardModal(false);
              }}
              onClose={() => setOpenWizardModal(false)}
              onChange={(event: React.ChangeEvent<any>, value: any) => {
                // if(["KnownVehicleFilter", "ALPR - coming soon!", "Loitering - coming soon!", "Tailgating - coming soon!", "NoGuardAlert", "LeftObject"].includes(value.replace(" ", ""))){
                //   return
                // }

                // console.log("KnownVehicleFilter -->", value.replace(" ", ""));
                // if (["KnownVehicleFilter"].includes(value.replace(/\s/g, ""))) {
                //   setOpenWizardModal(false);
                //   return setOpenALPRModal(true);
                // }

                handleChangeAuto(value);
              }}
              // value={optionValue}
              value={ctx.rule_type}
              options={options}
              upcomingOptions={upcomingOptions}
              appStore={appStore}
            /> */}
            {sensorDefinedRules && (
              <SelectRulesModal
                openModal={openWizardModal}
                title={"Select Type of Rule"}
                onContinue={() => {
                  setShowWizard(true);
                  setOpenWizardModal(false);
                }}
                onClose={() => setOpenWizardModal(false)}
                onChange={(event: React.ChangeEvent<any>, value: any) => {
                  // if(["KnownVehicleFilter", "ALPR - coming soon!", "Loitering - coming soon!", "Tailgating - coming soon!", "NoGuardAlert", "LeftObject"].includes(value.replace(" ", ""))){
                  //   return
                  // }

                  // console.log("KnownVehicleFilter -->", value.replace(" ", ""));
                  // if (["KnownVehicleFilter"].includes(value.replace(/\s/g, ""))) {
                  //   setOpenWizardModal(false);
                  //   return setOpenALPRModal(true);
                  // }

                  handleChangeAuto(value);
                }}
                // value={optionValue}
                value={ctx.rule_type}
                options={sensorDefinedRules}
              />
            )}
            {/* <p className={classes.bold_text}>Advanced Rules</p>
            &nbsp; &nbsp;
            {!site?.data_origin?.includes("frontel") &&
              !site?.data_origin?.includes("ajax") &&
              !site?.data_origin?.includes("immix") && (
                <CustomizedSwitch
                  checked={advancedCheck}
                  setChecked={(e: boolean) => {
                    advancedRulesHandler(e);
                  }}
                  label=""
                />
              )} */}
          </div>
        )}
        <br />
        {/* <Divider /> */}
        {advancedCheck && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              paddingBottom: 30,
            }}
          >
            {!showWizard && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <Zone
                    // closeWizard={() => setShowWizard(false)}
                    handleNext={() => {}}
                    appStore={appStore}
                    sensor={sensor}
                    site={site}
                    showWizard={showWizard}
                    showTable={false}
                    setOpenWizardModal={setOpenWizardModal}
                    saveZonesHandler={saveZonesHandler}
                    sensorDefinedRule={optionValue}
                    sensorDefinedRules={sensorDefinedRules}
                  />
                </div>
                {/* add buttons */}
              </div>
            )}
          </div>
        )}
        {/* {advancedCheck && showWizard && (
          <AdvancedRulesWizard
            closeWizard={() => setShowWizard(false)}
            appStore={appStore}
            sensor={sensor}
            site={site}
            setOpenWizardModal={setOpenWizardModal}
            saveZonesHandler={saveZonesHandler}
          />
        )} */}
        {advancedCheck && showWizard && sensorDefinedRules && (
          <RulesWizard
            closeWizard={() => setShowWizard(false)}
            appStore={appStore}
            sensor={sensor}
            site={site}
            setOpenWizardModal={setOpenWizardModal}
            saveZonesHandler={saveZonesHandler}
            sensorDefinedRule={optionValue}
            sensorDefinedRules={sensorDefinedRules}
          />
        )}
      </Card>
    </div>
  );

  if (!loading) {
    return (
      <>
        <div className="main-drawer-container">
          <div className={classes.location_header}>
            <div className={classes.expansion_area}>
              <div className={classes.shelf_container}>
                <div
                  className={classes.grab_icon}
                  onClick={() => {
                    setSelectedItem(site);
                    ctx.clearRulesState();
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"
                      fill="#15325F"
                      fill-opacity="0.87"
                    ></path>
                  </svg>
                </div>
                <div className={classes.title_icon}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"
                      fill="#15325F"
                      fill-opacity="0.87"
                    ></path>
                  </svg>
                </div>
                <div className={classes.header_text}>
                  {sensor.sensor_id_str +
                    " " +
                    "(Camera ID=" +
                    sensor.sensor_id +
                    ") " +
                    sensor.human_name +
                    "   (" +
                    site?.name +
                    ")"}
                </div>
              </div>
            </div>
          </div>
          <AppBar position="static" className="navcolor">
            <Tabs
              value={tabvalue}
              indicatorColor="secondary"
              textColor="inherit"
              onChange={handleTabChange}
              centered={true}
            >
              {tabsMenuData?.map((tab) => {
                return (
                  <Tab
                    value={tab.value}
                    label={tab.label}
                    onClick={() => getActiveTab(tab.label)}
                  />
                );
              })}
              {/* {tabsmenu} */}
            </Tabs>
          </AppBar>

          {frametab}
          {site ? (
            site.data_origin === "alert_smtp_client" ? (
              <>
                {smtpSettingsTab}
                {smtpConnectionDetailsTab}
                {tabvalue === 4 ? settingstab : <></>}
                {tabvalue === 4 ? noteUpdate : <></>}
                {tabvalue === 4 ? updateButton : <></>}
                {talkbackTab}
              </>
            ) : site?.data_origin === "alert_rest_client" ||
              site?.data_origin === "sentinel_http_edge_human1" ? (
              <>
                {tabvalue === 1 ? sentinnelAndAlertRestSettings : <></>}
                {tabvalue === 1 ? updateButton : <></>}
                {tabvalue === 4 ? settingstab : <></>}
                {tabvalue === 4 ? noteUpdate : <></>}
                {tabvalue === 4 ? updateButton : <></>}
                {talkbackTab}
              </>
            ) : site?.data_origin?.includes("motioncam") ||
              site?.data_origin?.includes("mobcam") ||
              site?.data_origin?.includes("objectcam") ? (
              <>
                {tabvalue === 1 ? settingstab : <></>}
                {tabvalue === 1 ? updateButton : <></>}
                {tabvalue === 2 ? advancedtab : <></>}
                {tabvalue === 2 ? updateButton : <></>}
                {testtab}
                {talkbackTab}
              </>
            ) : (
              <>
                {tabvalue === 1 ? settingstab : <></>}
                {tabvalue === 1 ? updateButton : <></>}
                {talkbackTab}
              </>
            )
          ) : null}
          {commonDataOrigin ? (
            <>{tabvalue === 6 ? hdSettingsTab : <></>}</>
          ) : null}
          {tabvalue === 8 ? advancedRulesTab : <></>}

          <MessageModal
            open={messagemodalopen}
            onClose={closeMessageModal}
            message={popmessage}
            elements={undefined}
          />
        </div>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="right"
        >
          <Divider />
          {site?.site_id && (
            <RightDrawer
              mid={sensor.sensor_id} // Sensor Id
              type={"sensor_id"}
              interval={interval}
              activity={activity}
              sensorhealthtimeline={sensorhealthtimeline}
              healthtimeline={healthtimeline}
              health={healthsites}
              sensorhealth={sensorhealth}
              setIntervalvalue={handleIntervalChange}
              mainMenu={mainMenu}
              showFullModalHandler={showFullModalHandler}
              siteHealthModal={siteHealthModal}
              site_id={site!.site_id}
            ></RightDrawer>
          )}
        </Drawer>
      </>
    );
  } else {
    return (
      <Container maxWidth="md">
        <div className="wait-container">
          <svg width="48px" height="48px" viewBox="0 0 128 128">
            <g>
              <linearGradient id="linear-gradient">
                <stop offset="0%" stop-color="#ffffff" />
                <stop offset="100%" stop-color="#22305f" />
              </linearGradient>
              <path
                d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                fill="url(#linear-gradient)"
                fill-rule="evenodd"
              />
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 64 64"
                to="360 64 64"
                dur="1080ms"
                repeatCount="indefinite"
              ></animateTransform>
            </g>
          </svg>
        </div>
      </Container>
    );
  }
};

export default CameraMainDrawer;
